export const Text  = {
  canadaSite: {
    nav0: {
      "en":  'HOME',
      "cn":  '首页'
    },
    nav1: {
      "en":  'Appointment',
      "cn":  '预约申请'
    },
    nav2: {
      "en":  'CONTACT',
      "cn":  '专业咨询'
    },
    nav3: {
      'en': 'U.S. Visa',
      'cn': '美国签证',
    },
    welcome0: {
      'en': 'PROFESSIONAL VISA CONSULTING',
      'cn': '专业签证咨询',
    },
    welcome1: {
      'en': 'Our experienced team of consultants is dedicated to simplifying the immigration process, ensuring a stress-free journey for you and your loved ones.',
      'cn': '我们的专业咨询团队致力于优化签证移民程序，确保您及家人的海外之旅轻松愉快',
    },
    welcome2: {
      'en': 'Apply For Visa',
      'cn': '开始咨询',
    },
    welcome3: {
      'en': 'Non-Immigrant',
      'cn': '非移民签证申请',
    },
    welcome4: {
      'en': 'EXPLORE NOW',
      'cn': '探索了解更多',
    },
    trust0: {
      'en': 'CAEX Trusted By',
      'cn': 'CAEX Canada Express ',
    },
    trust1: {
      'en': 'Big Companies',
      'cn': '备受各大公司信任',
    },
    trust2: {
      'en': 'Streamline complex processes, enhance efficiency, and drive success.',
      'cn': '简化复杂流程，提高效率，推动成功。',
    },
    about0: {
      'en': 'ABOUT CAEX',
      'cn': '关于CAEX',
    },
    about1: {
      'en': 'Best Non-Immigration and Visa Consultation',
      'cn': '专业非移民和签证咨询',
    },
    about2: {
      'en': 'Connecting Cultures, Simplifying Visas: Your Pathway to the World Awaits!',
      'cn': '连接文化，简化签证：通往世界的路径等您来探索！',
    },
    about3: {
      'en': 'Immigration Resources',
      'cn': '非移民/移民 资源',
    },
    about4: {
      'en': 'Our platform offers up-to-date guides on visa requirements, application procedures, and legal regulations.',
      'cn': '我们的平台提供最新的签证要求、申请程序和法律法规指南。',
    },
    about5: {
      'en': 'Apply Visa Online',
      'cn': '网上申请签证',
    },
    about6: {
      'en': 'Our platform guides you step-by-step through filling out forms, submitting documentation, and tracking application status.',
      'cn': '我们的平台指导您逐步填写表格，提交文件，并跟踪申请状态。',
    },
    about7: {
      'en': 'MORE ABOUT US',
      'cn': '更多关于',
    },
    about8: {
      'en': 'Contact Agent',
      'cn': '联系我们',
    },
    cate0: {
      'en': 'VISA CATEGORIES',
      'cn': '签证类型',
    },
    cate1: {
      'en': 'We Provide all Visa You Need',
      'cn': '我们提供您所需的所有签证',
    },
    cate2: {
      'en': 'Student Visa',
      'cn': '学生签证',
    },
    cate3: {
      'en': 'A student visa is a type of visa specifically designed for individuals looking to pursue their education abroad.',
      'cn': '学生签证是一种专门为希望在国外接受教育的个人设计的签证。',
    },
    cate4: {
      'en': 'Worker Visa',
      'cn': '工作签证',
    },
    cate5: {
      'en': 'A worker visa is a permit that allows individuals from other countries to legally work in a host nation for a specified period. ',
      'cn': '工作签证是一种允许其他国家的个人在东道国合法工作一段时间的许可证。',
    },
    cate6: {
      'en': 'Tourist Visa',
      'cn': '旅游签证',
    },
    cate7: {
      'en': 'A tourist visa is a document that permits entry into a country for the purpose of tourism, including leisure, sightseeing, and visiting friends or family.',
      'cn': '旅游签证是一种允许以旅游为目的进入一个国家的证件，包括休闲、观光和探亲访友。',
    },
    cate8: {
      'en': 'Business Visa',
      'cn': '商务签证',
    },
    cate9: {
      'en': 'A business visa is a type of visa specifically tailored for individuals engaging in business-related activities such as meetings, conferences, negotiations, and other short-term commercial endeavors',
      'cn': '商务签证是一种专门为从事商务相关活动的个人量身定制的签证，如会议、谈判和其他短期商业活动。',
    },
    cate10: {
      'en': 'Family Visa',
      'cn': '家庭签证',
    },
    cate11: {
      'en': 'A family visa is a type of visa designed to facilitate the reunion of families by allowing individuals to join relatives who are residing permanently or working in a foreign country.',
      'cn': '亲属签证是一种旨在促进家庭团聚的签证，允许个人与在外国永久居住或工作的亲属团聚。',
    },
    cate12: {
      'en': 'Super Visa',
      'cn': '超级签证',
    },
    cate13: {
      'en': 'A Super Visa is a special type of visa designed primarily for parents and grandparents of permanent residents or citizens of certain countries.',
      'cn': '级签证是一种特殊类型的签证，主要是为永久居民或某些国家公民的父母和祖父母设计的。',
    },
    success0: {
      'en': 'Satisfied Clients',
      'cn': '满意的客户',
    },
    success1: {
      'en': 'Expert Consultant',
      'cn': '专业顾问',
    },
    success2: {
      'en': 'Successful Visa Case',
      'cn': '成功案例',
    },
    success3: {
      'en': 'Years Of Experience',
      'cn': '行业经验（年）',
    },
    success4: {
      'en': 'We Provide The Best Way To Success Your Visa',
      'cn': '为您提供获得签证的最佳途径,最节省时间和工序',
    },
    success5: {
      'en': 'Our team of experienced immigration consultants uses their extensive knowledge and up-to-date information to guide you through every step, from initial consultation to visa approval.',
      'cn': '我们经验丰富的移民顾问团队，将利用他们丰富的知识和最新的信息，指导您从初步咨询到签证批准的每一个步骤。',
    },
    success6: {
      'en': 'MORE ABOUT US',
      'cn': '咨询了解更多',
    },
    review00: {
      'en': 'CLIENT FEEDBACK',
      'cn': '真实客户反馈',
    },
    review0: {
      'en': 'Best Review From Our Clients',
      'cn': '客户的五星反馈',
    },
    review1: {
      'en': 'Here are some customer real reviews that show the effectiveness and quality of our visa service and consultation offerings.',
      'cn': '这是一些客户的真实评价，显示了我们签证和咨询服务的有效性和质量',
    },
    review2: {
      'en': "They helped me secure my work visa in record time with no hassle. The team was proactive, keeping me informed at every step, and their attention to detail ensured a flawless application process.",
      'cn': '我是从同事那里知道的CAEX！他们帮我在很短的时间内就搞定了工作签证。这个过程中他们都非常积极主动，每一步都会通知我进展。能感觉到他们对细节的把握很赞。',
    },
    review3: {
      'en': 'Software Engineer',
      'cn': '软件工程师',
    },
    review4: {
      'en': "Their knowledgeable consultants made all the difference, offering personalized guidance that really paid off. Thanks to them, I'm now ready to start my studies abroad with peace of mind.",
      'cn': '在找到CAEX之前，我觉得办理学生签证的过程特别复杂。幸好遇到了他们知识丰富的顾问，给了我很多贴心的建议和帮助。多亏了他们，我现在终于可以安心地开始我的留学生涯了。',
    },
    review5: {
      'en': 'Graduate Student',
      'cn': '研究生',
    },
    review6: {
      'en': "As a business owner, I needed a straightforward and efficient visa process. Their expert advice and prompt service enabled me to focus on my business rather than paperwork.",
      'cn': '我是一个私营业主，目前在创业阶段，时间很紧张，我想找一个简便高效的签证助手，CAEX正好满足了我的需求。他们的专业建议和贴心服务让我能够专注于业务，不需要为繁琐的文书工作操心。',
    },
    review7: {
      'en': 'Entrepreneur',
      'cn': '网店店主',
    },
    review8: {
      'en': "I had a fantastic experience with the company while applying for my tourist visa. The team was friendly, professional, and very thorough, ensuring that my application was perfect. Thanks to their expertise, my dream vacation went off without a hitch!",
      'cn': '申请旅游签证的整个过程下来我就能体会到这个团队真的很专业，而且非常细心。他们一直尽力确保我的申请万无一失，我的签证很顺利就下来了。',
    },
    review9: {
      'en': 'Graphic Designer',
      'cn': '平面设计师',
    },
    review10: {
      'en': "The team at CAEX provided exceptional service when I applied for my family visa. Their compassionate approach and dedication made a typically stressful process smooth and easy. We're grateful for their support in reuniting our family!",
      'cn': '在我申请家庭签证的时候，CAEX的团队提供了非常周到的服务。接触下来除了专业的指导之外，我感受到他们真的很敬业，致力吧每一件小细节都照顾到。我和家人都很感谢他们的帮助的一路以来的努力。',
    },
    review11: {
      'en': 'Sales Director',
      'cn': '销售主管',
    },
    review12: {
      'en': "I approached CAEX for help with my immigration application, and I was thoroughly impressed by their level of service. Their consultants are highly experienced and genuinely care about your success. They were a major factor in achieving a positive outcome for my case.",
      'cn': '我在准备申请移民的过程中找到了CAEX，他们的服务确实让我眼前一亮。我的顾问非常有经验，而且真的把我的事放在心上，给了我专业的分析和指导，时刻追踪我的情况。我能如此顺利，真的是多亏了他们的帮助。',
    },
    review13: {
      'en': 'Teacher',
      'cn': '中学老师',
    },
  },
  landingPage:{
    heading: {
      "en":  'Hello!',
      "cn":  '欢迎！'
    },
    heading2: {
      "en":  'Choose a country.',
      "cn":  '选择计划签证国家'
    },
    desc: {
      "en":  'Seeking Visa Consultation Services?',
      "cn":  '签证办理咨询？'
    },
    desc2: {
      "en":  'For US visa applications, select "United States"; for Canadian visas, choose "Canada."',
      "cn":  '选对于美国签证申请，请选择“美国”；对于加拿大签证，请选择“加拿大”。'
    },
    us: {
      "en":  'United States',
      "cn":  '美国'
    },
    ca: {
      "en":  'Canada',
      "cn":  '加拿大'
    },
    slogan1: {
      "en":  'Navigating Your Journey to North Amercia Access.',
      "cn":  '导航您的北美通行之旅, 涵盖学签、工签、旅游签、过境签、特殊签等'
    },
    slogan2: {
      "en":  'Expert Guidance, Global Reach.',
      "cn":  '持牌顾问，专业指导，全球覆盖'
    },
    slogan3: {
      "en":  'Unlock the World: Expert Visa Services at Your Fingertips.',
      "cn":  '解锁北美：触手可及的专家签证服务'
    },
    slogan4: {
      "en":  'Global Entry, Local Expertise.',
      "cn":  '全球受理，北美本地专业注册咨询公司'
    },
    slogan5: {
      "en":  'Your Journey Starts Here: Professional Visa Assistance.',
      "cn":  '旅程从这里实现：CAEX开始咨询'
    }
  },
  nav0: {
    "en":  'Home',
    "cn":  '首页'
  },
  nav1: {
    "en":  'Appointment',
    "cn":  '预约 / 申请'
  },
  nav2: {
    "en":  'Subscription',
    "cn":  '美签空位提醒订阅'
  },
  nav3: {
    "en":  'Professional Consultation',
    "cn":  '专业咨询'
  },
  nav4: {
    "en":  'Xhs Red Book',
    "cn":  '小红书精品帖子'
  },
  nav5: {
    "en":  'FAQ',
    "cn":  '常见问题'
  },
  nav6: {
    "en":  'Dashboard',
    "cn":  '个人中心'
  },
  nav7: {
    "en":  'DS-160 Forms',
    "cn":  'DS-160 审核提交'
  },
  nav8: {
    "en":  'Little Red Book',
    "cn":  '实用资源'
  },
  nav9: {
    'en': 'Canada Visa',
    'cn': '加拿大签证',
  },
  nav10: {
    'en': 'Customize',
    'cn': '定制服务',
  },
  index0: {
    "en":  'Express Consulting',
    "cn":  '轻松办理签证'
  },
  index01: {
    "en":  'It\'s',
    "cn":  '就选'
  },
  index02: {
    "en":  '',
    "cn":  ' 开始咨询'
  },
  index03: {
    "en":  '#A Partner that you can trust',
    "cn":  '#你值得信赖的签证助手'
  },
  index03m: {
    "en":  '#A Trusty Partner',
    "cn":  '#你值得信赖的签证助手'
  },
  index10: {
    "en":  'Recent Expedited Appointments',
    "cn":  '加急案例 · 近期'
  },
  index11: {
    "en":  ' (Within the Last Three Days)',
    "cn":  '近期最新成功预约情况'
  },
  index12: {
    "en":  'F1',
    "cn":  'F1'
  },
  index13: {
    "en":  'Toronto',
    "cn":  '多伦多'
  },
  index14: {
    "en":  'B1/B2 Visa (Initial Apply) ',
    "cn":  '旅游签证 · 首签'
  },
  index15: {
    "en":  'Requirements: Urgent Application',
    "cn":  '需求: 加急申请'
  },
  index16: {
    "en":  'Toronto, Applicant D',
    "cn":  '多伦多 D申请人'
  },
  index17: {
    "en":  '04/11/25',
    "cn":  '04/11/2025'
  },
  index18: {
    "en":  'Visa Comprehensive',
    "cn":  '面签全方位'
  },
  index19: {
    "en":  'H1B',
    "cn":  'H1B'
  },
  index20: {
    "en":  'Vancouver',
    "cn":  '温哥华'
  },
  index21: {
    "en":  'H1B Visa (Initial Apply) ',
    "cn":  '工作签证 · 首签'
  },
  index22: {
    "en":  'End of July 2025',
    "cn":  '需求: 7月尾巴'
  },
  index23: {
    "en":  'WA, Applicant C',
    "cn":  '西雅图 C申请人'
  },
  index24: {
    "en":  '07/29/25',
    "cn":  '02/29/2025'
  },
  index25: {
    "en":  'Interview Expedited',
    "cn":  '面签预约'
  },
  index26: {
    "en":  'B1/B2',
    "cn":  'B1/B2'
  },
  index27: {
    "en":  'Toronto',
    "cn":  '多伦多'
  },
  index28: {
    "en":  'B1/B2 Visa (Initial Apply) ',
    "cn":  '旅游签证 · 首签'
  },
  index29: {
    "en":  'Earliest of September',
    "cn":  '需求: 25年9月开学后'
  },
  index30: {
    "en":  'Toronto, Applicant B',
    "cn":  '多伦多 B申请人'
  },
  index31: {
    "en":  '09/06/25',
    "cn":  '09/06/2025'
  },
  index32: {
    "en":  'Montly Subscription',
    "cn":  '安心包月提醒'
  },
  index33: {
    "en":  'B1/B2',
    "cn":  'B1/B2'
  },
  index34: {
    "en":  'Calgary',
    "cn":  '卡尔加里'
  },
  index35: {
    "en":  'B1/B2 Visa (Initial Apply) ',
    "cn":  '旅游签证 · 首签'
  },
  index36: {
    "en":  'Sigle slot within 2024 year ',
    "cn":  '需求: 25年'
  },
  index37: {
    "en":  'Ottawa, Applicant K',
    "cn":  '卡尔加里 K申请人'
  },
  index38: {
    "en":  '05/13/25',
    "cn":  '05/13/2025'
  },
  index39: {
    "en":  'Interview Expedited',
    "cn":  '面签预约'
  },
  index40: {
    "en":  'Litte Red Book Post',
    "cn":  '小红书 · 热门'
  },
  index41: {
    "en":  'Trending Post of U.S. Visa ',
    "cn":  '最🔥爆签相关实用帖'
  },
  index42: {
    "en":  'Calgary\'s Next Week Slots',
    "cn":  '卡尔加里下周面试位置'
  },
  index43: {
    "en":  'B1/B2 Visa Type',
    "cn":  '旅游签类型限时放位'
  },
  index44: {
    "en":  'B2 Tourist and Family Visit Visa',
    "cn":  'B2旅游签证父母探亲邀请函我该怎么写？'
  },
  index45: {
    "en":  'Finally meet my parent',
    "cn":  '爸妈终于能来了'
  },
  index46: {
    "en":  'What is a Transit Visa?',
    "cn":  '过境签是什么？'
  },
  index47: {
    "en":  'Could I leave the airport',
    "cn":  '我能出机场吗？'
  },
  index48: {
    "en":  'U.S. Visa Consulates',
    "cn":  '签证美国领事馆'
  },
  index49: {
    "en":  'The U.S. Consulates in Canada',
    "cn":  '加拿大各城市美国领事馆'
  },
  index50: {
    "en":  'Pacific Time',
    "cn":  '美西时间'
  },
  index51: {
    "en":  'Ski + Visa',
    "cn":  '滑雪 + 签证'
  },
  index52: {
    "en":  'Fast / High Demand',
    "cn":  '学签 / 工签 速度快'
  },
  index53: {
    "en":  'Eastern Time',
    "cn":  '美东时间'
  },
  index54: {
    "en":  'Shopping',
    "cn":  '探店 + 签证'
  },
  index55: {
    "en":  'Most Popular',
    "cn":  '预约最火爆'
  },
  index56: {
    "en":  'Delay',
    "cn":  'Delay频繁'
  },
  index57: {
    "en":  'High Acceptance',
    "cn":  '友好通关率高'
  },
  index58: {
    "en":  'Fast',
    "cn":  '审核速度快'
  },
  index59: {
    "en":  'PST+1hr',
    "cn":  '美西时间+1小时'
  },
  index60: {
    "en":  'High Availability',
    "cn":  '人少'
  },
  index61: {
    "en":  'Popular',
    "cn":  '最受欢迎之一'
  },
  index62: {
    "en":  'Eastern Time',
    "cn":  '美东时间'
  },
  index63: {
    "en":  'TNT Alternatives',
    "cn":  '多伦多的备选城市'
  },
  index64: {
    "en":  'Strict',
    "cn":  '严格'
  },
  index65: {
    "en":  'B1/B2 Not Recommanded',
    "cn":  '旅游签不推荐'
  },
  index66: {
    "en":  'Eastern Time',
    "cn":  '美东时间'
  },
  index67: {
    "en":  'Tourism Place',
    "cn":  '旅游胜地'
  },
  index68: {
    "en":  'F1 / H1B',
    "cn":  '学签 / 工签'
  },
  index69: {
    "en":  'EST + 1hr',
    "cn":  '美东时间+1小时'
  },
  index70: {
    "en":  'Tourism Place',
    "cn":  '旅游胜地'
  },
  index71: {
    "en":  'Few Cases',
    "cn":  '受理少'
  },
  index72: {
    "en":  'Eastern Time',
    "cn":  '美东时间'
  },
  index73: {
    "en":  'Not Recommand',
    "cn":  '不推荐'
  },
  index74: {
    "en":  'Service Process',
    "cn":  '流程指示'
  },
  index75: {
    "en":  'Start your plan immediately',
    "cn": '急速开启私人订制的签证安排'
  },
  index76: {
    "en":  'Start Consultation',
    "cn": '一键咨询'
  },
  index77: {
    "en":  'Scan to add official WeChat',
    "cn": '扫码添加官方微信，联系值班老师 / 客服'
  },
  index78: {
    "en":  'When adding, please include your desired city (1-2) + visa type + preferred time range in the note.',
    "cn": '添加时候请备你所在城市 + 需求'
  },
  index79: {
    "en":  'About Services',
    "cn": '了解服务'
  },
  index80: {
    "en":  'Option 1: After familiarizing yourself with our service offerings, select the visa package that suits you.',
    "cn": '方案一：自行了解服务种类后，选择自己想要的签证套餐'
  },
  index81: {
    "en":  'Option 2: Consult with customer service representative to decide on the visa package that\'s right for you.',
    "cn": '方案二：经过老师讲解后，决定适合你的签证套餐'
  },
  index82: {
    "en":  'Make Payment',
    "cn": '支付费用'
  },
  index83: {
    "en":  'Click to select your payment method, screenshot the payment confirmation, and send it to our customer service representative. (We also offer third-party payment options like PayPal, bank transfers, and E-Transfer. Please contact customer service for detailed payment information.)',
    "cn": '选择适合的服务类型和数量前往付款，截图付款成功回执，发送给客服。支持Credit、Debit、微信支付宝等支付方式'
  },
  index84: {
    "en":  'Start Service',
    "cn": '开始服务'
  },
  index85: {
    "en": 'According to the service type you choose to arrange the corresponding service for you in the first time, and arrange the specialist consultant to track the progress for you throughout the process.',
    "cn": '根据你选择的服务类型给你第一时间安排对应的服务，并安排专员顾问全程为你跟踪进度'
  },
  index86: {
    "en":  'Appointment Status',
    "cn": '签证位置实况'
  },
  index87: {
    "en":  'Updated availabilities of all cities',
    "cn": '最新各城市美领事馆实况持续更新'
  },
  index88: {
    "en":  '(data maybe have couple mins delay)',
    "cn": '（数据展示存在延迟,可前往个人中心查看或者了解实时订阅提醒服务）'
  },
  index89: {
    "en":  'User Agreement',
    "cn": '用户协议'
  },
  index90: {
    "en":  'Privacy Policy',
    "cn": '隐私政策'
  },
  index91: {
    "en":  'Contact US',
    "cn": '联系我们'
  },
  index92: {
    "en":  'Service Time: PST ( 9am - 5pm ) ',
    "cn": '服务时间：太平洋时间 （9am - 5pm）'
  },
  index93: {
    "en":  'Customer Service: support@caex-consulting.com',
    "cn": '服务联系：support@caex-consulting.com'
  },
  index94: {
    "en":  'Latest Consulate Announcements',
    "cn": '最新领事馆公告'
  },
  index95: {
    "en":  'One-stop Service',
    "cn": '一站式美签服务'
  },
  index96: {
    "cn":  '解放双手让签证变得简单，“嗖”地一下就能出发',
    "en": 'freeing up your hands makes visa application simple, and you can set off in just one click'
  },
  index97: {
    "en":  'Contact',
    "cn": '点我咨询'
  },
  index98: {
    "en":  'Service Overview',
    "cn": '服务目标'
  },
  index99: {
    "en":  'Improve the visa pass rate in all aspects through material preparation, polishing, language refinement, and completeness review.',
    "cn": '通过材料准备、润色、语言精修、完整性审核，全方位提升签证通过率'
  },
  index100: {
    "en":  'Covered Regions',
    "cn": '涵盖地区'
  },
  index101: {
    "en":  'United States | Canada | Mainland China | Hong Kong | Others (please contact us)',
    "cn": '美国 | 加拿大 | 中国大陆 | 香港 | 其他（请联系我们）'
  },
  index102: {
    "en":  'Target User',
    "cn": '适合人群'
  },
  index103: {
    "en":  'who do not understand the visa process; do not have time to make an appointment, and hope to fully improve the interview pass rate.',
    "cn": '对签证流程不了解；没有时间预约位置，希望全面提升面签通过率的小伙伴'
  },
  index104: {
    "en":  'About Visa',
    "cn": '关于美签'
  },
  index105: {
    "en":  'One time apply, easiler for decade',
    "cn": '一次精心的准备，多年无忧进出'
  },
  index106: {
    "en":  'Do you know?',
    "cn": '你知道吗?'
  },
  index107: {
    "en":  'Only 20% of people understand the importance of advance preparation for the in-person interview and are familiar with the interview process.',
    "cn": '20%的人清楚面签提前准备的重要性，以及如何面签'
  },
  index108: {
    "en":  'On average, the system opens up a minimum of 5 new appointment slots every day.',
    "cn": '系统平均每天最少会发放5个新的预约位置'
  },
  index109: {
    "en":  '75% of applicants are automatically screened out by the machine before their interview with the visa officer.',
    "cn": '75%的申请人在与签证官会面前就被机器自动淘汰'
  },
  index110: {
    "en":  'No Preferred Appointment Slots? ',
    "cn": '没有心仪的预约位置?'
  },
  index111: {
    "en":  'Why Did the First Visa Denial? ',
    "cn": '面签首次被拒问题出在哪？'
  },
  index112: {
    "en":  'What Questions Can I Expect from the Visa Officer?',
    "cn": '面试官一般会什么问题，我应该准备什么文件？'
  },
  index113: {
    "en":  'How to Improve Your Visa Interview Success Rate and Secure a U.S. Visa?',
    "cn": '如何提高面签成功率，斩获美帝签证？'
  },
  index114: {
    "en":  'Service Highlights',
    "cn": '服务亮点'
  },
  index115: {
    "en":  'Visa assistants with more than 10 years of experience check documents line by line and quickly increase the interview pass rate',
    "cn": '拥有10年以上经验的签证助手逐行逐句检查文件，迅速提升面签通过率'
  },
  index116: {
    "en":  'Expertise',
    "cn": '专业化：'
  },
  index117: {
    "en":  'Our team includes former U.S. visa interview officials who have interviewed numerous applicants. They help you identify and address any preparation issues, ensuring a successful interview for your desired visa.',
    "cn": '阅人无数的前美签面签官，帮你针对心仪签证，发现准备问题，成功拿下面试。'
  },
  index118: {
    "en":  'Personalized:',
    "cn": '个性化：'
  },
  index119: {
    "en":  'Our consultants refine your document submissions based on your unique experiences and application type, addressing any shortcomings.',
    "cn": '顾问会根据你的个人经历和申请类型，补充润色文件填写的不足之处。'
  },
  index120: {
    "en":  'Detailed Language Refinement',
    "cn": '逐行润色精修细节化：'
  },
  index121: {
    "en":  'Native speakers provide a thorough linguistic review, ensuring that your application documents are naturally worded, authentic, and well-structured.',
    "cn": 'Native Speaker逐行语言润色，保证申请文件语言丰富地道合理。'
  },
  index122: {
    "en":  'AI Automatic Service: ',
    "cn": 'AI 自动化:'
  },
  index123: {
    "en":  'A 24/7 automated system monitors booking new US visa interview appoinment for our client',
    "cn": '24/7自动化系统监控预约最新预约情况，为申请人第一时间锁定心仪位置。'
  },
  index122_2: {
    "en":  'Comprehensive Service: ',
    "cn": '全方位系统化:'
  },
  index123_2: {
    "en":  'We handle all aspects, from preparing application materials and completing DS-160 forms to setting up appointment accounts and securing interview locations. We ensure a smooth and hassle-free process!',
    "cn": '私人定制的一站式美签申请，助您一步到位，轻松获签。包括以下所有流程:'
  },
  index124: {
    "en":  'Details',
    "cn": '服务详情'
  },
  index125: {
    "en":  'Consult first, then prepare your visa interview schedule systematically.',
    "cn": '先咨询后服务，科学安排面签计划'
  },
  index126: {
    "en":  'Express Appointment',
    "cn": '加急预约'
  },
  index127: {
    "en":  'Customized Apply',
    "cn": '专属定制申请'
  },
  index128: {
    "en":  'Comprehensive Service',
    "cn": '安心全方位'
  },
  index129: {
    "en":  'Express',
    "cn": '预约'
  },
  index130: {
    "en":  'Apply',
    "cn": '申请'
  },
  index131: {
    "en":  'Comprehensive',
    "cn": '全方位'
  },
  index132: {
    "en":  'Slot Reservation',
    "cn": '位置锁定（Slot）'
  },
  index133: {
    "en":  '- 1 to 3 preferred cities',
    "cn": '- 1-3个心仪城市挑选'
  },
  index134: {
    "en":  '- Preferred times',
    "cn": '- 心仪预约时间挑选'
  },
  index135: {
    "en":  '- Progress tracking',
    "cn": '- 专人客服进度跟踪'
  },
  index136: {
    "en":  '- 24hrs AI Detecting',
    "cn": '- 人工智能技术24小时监控'
  },
  index137: {
    "en":  'Document Checklist',
    "cn": '面签文件 Checklist'
  },
  index138: {
    "en":  'DS-160 Form Completion',
    "cn": 'DS160文件填写（检查润色）'
  },
  index139: {
    "en":  'Visa Appointment Account Registration',
    "cn": '签证预约账号准备'
  },
  index140: {
    "en":  'Interview Training (Frequently Asked)',
    "cn": '面签问题培训（Frequent Ask）'
  },
  index141: {
    "en":  'Customized Q&A',
    "cn": '私人订制 Q&A'
  },
  index142: {
    "en":  '1 time Rescheduling Service',
    "cn": '【官方】1次改签服务'
  },
  index143: {
    "en":  'Expedited Submission / Waiver',
    "cn": '【官方】免面签材料递交，面签加急申请'
  },
  index144: {
    "en":  'Price',
    "cn": '价格'
  },
  index145: {
    "en":  '$150 USD',
    "cn": '$150 美金'
  },
  index146: {
    "en":  '$80 USD',
    "cn": '$80 美金'
  },
  index147: {
    "en":  '$280 USD',
    "cn": '$280 美金'
  },
  index148: {
    "en":  '$200 USD',
    "cn": '$200 美金'
  },
  index149: {
    "en":  '$400 USD',
    "cn": '$400 美金'
  },
  index150: {
    "en":  '$250 USD',
    "cn": '$250 美金'
  },
  index151: {
    "en":  'Select Plan',
    "cn": '选择付款'
  },
  index152:{
    "en": 'Match a plan to lock in your visa',
    "cn": '咨询计划 锁定你的签证'
  },
  index153:{
    "en": 'The professional team makes targeted plans and arranges to get the visa in one step',
    "cn": '专业团队针对性计划安排 快人一步拿到签证'
  },
  index154:{
    "en": 'Add our consultant',
    "cn": '添加顾问微信'
  },
  index155:{
    "en": 'on WeChat',
    "cn": '开始签证计划'
  },
  index156:{
    "en": 'Free',
    "cn": '免费咨询'
  },
  index157:{
    "en": 'consultation',
    "cn": '订制签证计划'
  },
  index158:{
    "en": 'Pay your plan.',
    "cn": '支付费用'
  },
  index159:{
    "en": 'Start Service',
    "cn": '信心满满的面签'
  },
  index160:{
    "en": 'FAQ',
    "cn": '常见问题'
  },
  index161:{
    "en": 'What do I need to prepare before scheduling an appointment? ',
    "cn": '预约抢位前需要我需要准备什么?'
  },
  index162:{
    "en": 'You\'ll need to complete the DS-160 form and obtain the AA confirmation number. Also, make sure you have a pre-paid appointment account ready.',
    "cn": '需要填写好DS160表格拿到AA确认号，准备好一个付款好的预约号。?'
  },
  index163:{
    "en": 'How many times can I schedule appointments after payment? ',
    "cn": '付款后，可以预约几次位置？'
  },
  index164:{
    "en": 'One time.',
    "cn": '1次，我们会根据你的需求以及领馆的放位情况实际安排，尽力约到最新的心仪位置。如需约不同的账号或者换账号约，需另行付费。'
  },
  index165:{
    "en": 'Is there a refund if I can\'t secure an appointment in my preferred location?',
    "cn": '如果约不到心仪的位置能退费吗？'
  },
  index166:{
    "en": 'No, we do not offer refunds. If you are unable to secure an appointment, we can assist you in arranging a new visa appointment based on your plans and requirements. If you wish to discontinue our services, please note that you will be responsible for any associated costs.',
    "cn": '不支持，如果没有你理想的位置放出，我们可以根据你的计划和需求帮你安排新的签证位置，如果你需要中断服务,需要你自己承担费用喔。'
  },
  index167:{
    "en": 'What\'s the service duration? ',
    "cn": '服务时长多久?'
  },
  index168:{
    "en": 'The service lasts for a total of one year.',
    "cn": '一共为期1年的服务时间。'
  },
  index169:{
    "en": 'Are there any promotions or discounts? ',
    "cn": '有活动或者优惠吗?'
  },
  index170:{
    "en": 'The current price is already the latest discounted rate and may revert to the original price at any time. We do offer a referral reward program; please contact our customer service for details.',
    "cn": '目前价格已经是最新的优惠价格，随时会恢复原价。我们有推荐奖励，详情请咨询客服'
  },
  index171:{
    "en": 'Official Wechat',
    "cn": '问题咨询'
  },
  indexS0:{
    "en": 'Select Your Exclusive Notification Package',
    "cn": '选择你的专属提醒套餐'
  },
  indexS1:{
    "en": 'All major visa types and the needs of various groups of people can be seen at a glance',
    "cn": '各大签证类型，各类人群需求，全部尽收眼底'
  },
  indexS2:{
    "en": 'Weekly',
    "cn": '急速包周'
  },
  indexS3:{
    "en": '(2 days free)',
    "cn": '(送2天)'
  },
  indexS4:{
    "en": 'Efficient and accurate prompts/screening of interview vacancies during the week',
    "cn": '高效精准提示/筛查周内面签空位'
  },
  indexS5:{
    "en": 'Only one visa type',
    "cn": '仅限一种签证类型'
  },
  indexS6:{
    "en": 'Choose 1 target city (within Canada)',
    "cn": '可选 1 个目标城市 （加拿大境内）'
  },
  indexS7:{
    "en": 'A fixed receiving email',
    "cn": '一个固定接收邮箱'
  },
  indexS8:{
    "en": 'Artificial intelligence 24-hour monitoring',
    "cn": '人工智能24小时监控'
  },
  indexS9:{
    "en": 'Internal data model, precise positioning',
    "cn": '内部数据模型，精准定位'
  },
  indexS10:{
    "en": 'Low latency, dynamically pushing the latest location information',
    "cn": '低延迟，动态推送最新位置信息'
  },
  indexS11:{
    "en": 'Dedicated assistant to solve problems',
    "cn": '专属小助手解决问题'
  },
  indexS12:{
    "en": 'One-time fee, no automatic deductions',
    "cn": '一次性收费，无自动扣款'
  },
  indexS13:{
    "en": 'Select payment',
    "cn": '选择付款'
  },
  indexS14:{
    "en": 'Monthly',
    "cn": '安心包月'
  },
  indexS15:{
    "en": '(10 days free)',
    "cn": '(送10天)'
  },
  indexS16:{
    "en": 'Efficient and accurate reminder/screening of interview vacancies within the month',
    "cn": '高效精准提示/筛查月内面签空位'
  },
  indexS17:{
    "en": 'Choose 2 target city (within Canada)',
    "cn": '可选 2 个目标城市 （加拿大境内）'
  },
  indexS18:{
    "en": 'Seasonal',
    "cn": '畅享包季'
  },
  indexS19:{
    "en": '(20 days free)',
    "cn": '(送20天)'
  },
  indexS20:{
    "en": 'Choose 3 target city (within Canada)',
    "cn": '可选 3 个目标城市 （加拿大境内）'
  },
  indexS21:{
    "en": 'Efficient and accurate reminder/screening for in-season interview vacancies',
    "cn": '高效精准提示/筛查季内面签空位'
  },
  indexS22:{
    "en": 'FAQ',
    "cn": '常见问题FAQ'
  },
  indexS23:{
    "en": 'Here is a collection of popular questions about our services',
    "cn": '这里是一些关于我们服务的热门问题的集合'
  },
  indexS24:{
    "en": 'What information do I need to provide to subscribe?',
    "cn": '我是需要提供什么信息来订阅？'
  },
  indexS25:{
    "en": 'You will need to provide your name and email so that we can create and manage your service.',
    "cn": '您需要提供姓名和邮箱，以便我们能够创建和管理您的服务。'
  },
  indexS26:{
    "en": 'Can I snooze notifications during my subscription?',
    "cn": '我可以在订阅期间暂停通知吗？'
  },
  indexS27:{
    "en": 'Yes, you can pause receiving notifications at any time during the subscription period, but the service time cannot be paused.',
    "cn": '可以的，在订阅期间可以随时暂停接收通知，但是服务时间不能暂停。'
  },
  indexS28:{
    "en": 'Can my subscription be transferred to someone else?',
    "cn": '我的订阅是否可以转让给他人？'
  },
  indexS29:{
    "en": 'Can my subscription be transferred to someone else?',
    "cn": '不可以，您的订阅是与您的个人邮箱绑定的，不得转让给他人使用。'
  },
  indexS30:{
    "en": 'After the service starts, can I change the receiving email address?',
    "cn": '服务开始后，我是否可以换接收邮箱地址？'
  },
  indexS31:{
    "en": 'Not supported, the new email address needs to be re-bind to the new service.',
    "cn": '不支持，新的邮箱需要重新绑定新的服务。'
  },
  indexS32:{
    "en": 'How long does it take after subscribing to start receiving notifications?',
    "cn": '订阅后多久会开始收到通知？'
  },
  indexS33:{
    "en": 'Notifications will be sent via email to the email address you initially provided.',
    "cn": '一旦您支付订阅费用，我们将立即开始监控签证空位并在下一次周期发送通知。'
  },
  indexS34:{
    "en": 'Are refunds offered after subscription starts?',
    "cn": '订阅开始后是否提供退款？'
  },
  indexS35:{
    "en": 'Sorry, we do not offer refunds. Once you subscribe to our plan, fees are non-refundable. Before subscribing, please make sure you fully understand your visa needs and plans.',
    "cn": '通很抱歉，我们不提供退款服务。一旦您订阅了我们的计划，费用将无法退还。在订阅之前，请确保您已充分了解您的签证需求和计划。'
  },
  indexS36:{
    "en": 'Can I change the selected country or city midway?',
    "cn": '能否中途更改已选定的国家或城市？'
  },
  indexS37:{
    "cn": '不好意思，一旦您订阅了特定的计划并选择了国家或城市，您将无法在订阅期间更改。请在订阅之前仔细确认您的选择。',
    "en": 'Sorry, once you subscribe to a specific plan and select a country or city, you cannot change it during the subscription period. Please confirm your choice carefully before subscribing.'
  },
  indexS38:{
    "cn": '通知能分享给朋友或者多人一起使用吗？',
    "en": 'Can I change the selected country or city midway?'
  },
  indexS39:{
    "cn": '不可以。我们的通知服务仅限个人使用，禁止将其连接到其他软件或接口，以确保公平使用和提供高质量的服务。一旦发现会立即单方面停止服务。',
    "en": 'Can\'t. Our notification service is for personal use only, and connecting it to other software or interfaces is prohibited to ensure fair use and high-quality service. Once discovered, the service will be immediately and unilaterally stopped.'
  },
  indexS40:{
    "cn": '订阅服务是否保证我一定能预约到签证空位？',
    "en": 'Does the subscription service guarantee that I will be able to reserve a visa slot?'
  },
  indexS41:{
    "en": 'Our notification service can provide timely visa availability information, but it does not guarantee that you will be able to make an appointment. The release of visa vacancies is related to the number of applicants, consulate policies and other factors. Our service is designed to provide you with the convenience of making an appointment.',
    "cn": '我们的通知服务能够提供及时的签证空位信息，但并不保证您一定能够预约到。签证空位的释放与申请人数、领馆政策等因素有关，我们的服务旨在为你提供预约的便利性。'
  },
  indexS42:{
    "en": 'How is the time in the plan calculated?',
    "cn": '计划中的时间是如何计算的？'
  },
  indexS43:{
    "en": 'Our plans follow a standard calendar, with a week containing 7 days, a month containing 31 days, and three months containing 93 days.',
    "cn": '我们的计划按照标准日历计算，一星期包含7天，一个月包含31天，三个月为93天。'
  },
  indexS44:{
    "en": 'Can I unsubscribe from reminders at any time?',
    "cn": '我可以随时取消订阅提醒吗？'
  },
  indexS45:{
    "en": 'Yes, you can cancel your subscription at any time. Once you cancel your subscription, you will no longer receive notifications and there will be no refunds.',
    "cn": '是的，您可以随时取消订阅。取消订阅后，您将不会再收到通知，且不会有任何退款。'
  },
  indexS46:{
    "en": 'What is the frequency of notifications?',
    "cn": '通知的频率是怎样的？'
  },
  indexS47:{
    "en": 'We will use artificial intelligence to monitor the visa vacancies at the consulate 24 hours a day and notify you when vacancies are released. The frequency of notifications depends on the availability of vacancies at the embassy and consulate and the update period.',
    "cn": '我们会24hr人工智能监控领馆的签证空位，并在有空位释放时通知您。通知的频率取决于使领馆释放空位的情况以及更新时期。'
  },
  indexS48:{
    "en": 'Will my subscription automatically renew?',
    "cn": '我的订阅是否会自动续订？'
  },
  indexS49:{
    "en": 'No, our subscriptions do not automatically renew. After the period of your subscription expires, you need to manually re-subscribe to continue using our services.',
    "cn": '不会，我们的订阅不会自动续订。您订阅的期限到期后，您需要手动重新订阅，以继续使用我们的服务。'
  },
  indexS50:{
    "en": 'Is there a trial period available?',
    "cn": '是否提供试用期？'
  },
  indexS51:{
    "en": 'We currently do not offer a trial period. You can choose the one that best suits your needs based on the grades we offer.',
    "cn": '目前我们暂时不提供试用期。您可以根据我们提供的档次，选择最适合您需求的。'
  },
  indexS52:{
    "en": 'How to contact my dedicated assistant?',
    "cn": '如何联系我的专属小助手？'
  },
  indexS53:{
    "en": 'After the service starts, we will provide contact information, or our assistant will also contact you.',
    "cn": '服务开始后，我们会提供联系方式，或者我们小助手也会联系您的。'
  },
  indexS54:{
    "en": 'Do you have any other questions?',
    "cn": '还有其他问题需要咨询？'
  },
  indexS55:{
    "en": 'We are happy to help',
    "cn": '我们很乐意为你解答'
  },
  indexS56:{
    "en": 'Contact us',
    "cn": '联系我们'
  },
  dsform0:{
    "en": 'Please fill out the information',
    "cn": '请输入完善信息'
  },
  dsform1:{
    "en": 'Passport Number',
    "cn": '护照号码'
  },
  dsform2:{
    "en": 'City and province of passport issuance',
    "cn": '护照签发城市和省份'
  },
  dsform3:{
    "en": 'Country where the passport is issued',
    "cn": '护照签发国家'
  },
  dsform4:{
    "en": 'Passport issuance date (YEAR-MONTH-DAY)',
    "cn": '护照签发日期（年-月-日）'
  },
  dsform5:{
    "en": 'Passport expiration date (YEAR-MONTH-DAY)',
    "cn": '护照到期日期（年-月-日）'
  },
  dsform6:{
    "en": 'Full name',
    "cn": '中文姓名'
  },
  dsform7:{
    "en": 'Name is in your native language',
    "cn": '姓名拼音'
  },
  dsform8:{
    "en": 'Former name ( fill N/A if No)',
    "cn": '曾用名（没有请填无）'
  },
  dsform9:{
    "en": 'Passport Book ID ( Mostly country dont have it please fill N/A if No)',
    "cn": '护照本编号（护照首页右侧竖着的一串黑色数字）'
  },
  dsform10:{
    "en": 'Gender',
    "cn": '性别'
  },
  dsform11:{
    "en": 'Date of birth',
    "cn": '出生日期'
  },
  dsform12:{
    "en": 'the Country of birth',
    "cn": '出生国家'
  },
  dsform13:{
    "en": 'State of birth',
    "cn": '出生省份（必须与护照首页信息一致）'
  },
  dsform13b:{
    "en": 'City of birth',
    "cn": '出生城市'
  },
  dsform14:{
    "en": 'ID number',
    "cn": '身份证号码'
  },
  dsform15:{
    "en": 'US Social Security Number (fill in “No” if not applicable)',
    "cn": '美国社保号（没有请填无）'
  },
  dsform16:{
    "en": 'American Tax Certificate Number (fill in “No”if not applicable)',
    "cn": '美国纳税证件号（没有请填无）'
  },
  dsform17:{
    "en": 'Current residence address (with zip code)',
    "cn": '现完整居住地址（包括邮编）'
  },
  dsform18:{
    "en": 'List the permanent resident otherthan your nationality (like Canada PR)',
    "cn": '列出你有的其它国家的永久居民身份（如枫叶卡）'
  },
  dsform19:{
    "en": 'Working telephone number',
    "cn": '工作电话'
  },
  dsform20:{
    "en": 'Email (Includes last 5 years)',
    "cn": '电子邮箱 (包括过去5年内使用过的）'
  },
  dsform21:{
    "en": 'Personal Telephone number',
    "cn": '手机电话'
  },
  dsform22:{
    "en": 'Other foreign languages except your native language',
    "cn": '除中文外，您会说其他语言'
  },
  dsform23:{
    "en": 'OHave you ever been arrested or convicted of a crime',
    "cn": '您是否曾因犯罪被逮捕或被判有罪'
  },
  dsform24:{
    "en": 'Father full name',
    "cn": '父亲姓名'
  },
  dsform25:{
    "en": 'Father full name',
    "cn": '父亲出生日期'
  },
  dsform26:{
    "en": 'Is your father currently in the US',
    "cn": '父亲现在是否在美国'
  },
  dsform27:{
    "en": 'Mother full name',
    "cn": '母亲姓名'
  },
  dsform28:{
    "en": 'Mother full name',
    "cn": '母亲出生日期'
  },
  dsform29:{
    "en": 'Is your mother currently in the US',
    "cn": '母亲现在是否在美国'
  },
  dsform30:{
    "en": 'Is your mother currently in the US',
    "cn": '母亲现在是否在美国'
  },
  dsform31:{
    "en": 'Your currently Marital status',
    "cn": '你目前婚姻状况'
  },
  dsform32:{
    "en": 'Name of spouse',
    "cn": '配偶姓名'
  },
  dsform33:{
    "en": 'Date of birth of spouse',
    "cn": '配偶出生日期'
  },
  dsform34:{
    "en": 'Nationality of spouse',
    "cn": '配偶国籍'
  },
  dsform35:{
    "en": 'Marriage Date',
    "cn": '结婚日期'
  },
  dsform36:{
    "en": 'Divorce Date',
    "cn": '离婚日期'
  },
  dsform37:{
    "en": 'Name of the workplace in your native language',
    "cn": '现任工作单位中文名'
  },
  dsform38:{
    "en": 'Name of the workplace in English',
    "cn": '现任工作单位英文名'
  },
  dsform39:{
    "en": 'Address of the workplace',
    "cn": '现任工作单位地址'
  },
  dsform40:{
    "en": 'Telephone of the workplace',
    "cn": '现任工作单位电话'
  },
  dsform44:{
    "en": 'Name of the supervisor',
    "cn": '主管名字'
  },
  dsform45:{
    "en": 'Telephone of the supervisor',
    "cn": '主管电话'
  },
  dsform46:{
    "en": 'Current job position',
    "cn": '现任工作职务'
  },
  dsform47:{
    "en": 'Date of current job initiaion',
    "cn": '现任工作起始时间'
  },
  dsform48:{
    "en": 'Monthly income(US Dollar)',
    "cn": '月收入（人民币）'
  },
  dsform49:{
    "en": 'Social media user name',
    "cn": '社交媒体账号（QQ / 微博 / 微信)'
  },
  dsform50:{
    "en": 'Purpose of the trip',
    "cn": '赴美目的（旅游、探亲、商务会议，据实填写）'
  },
  dsform51:{
    "en": 'Estimated date of the trip',
    "cn": '预计赴美日期'
  },
  dsform52:{
    "en": 'Estimated date of the staying in U.S.',
    "cn": '预计在美停留天数（大概）'
  },
  dsform53:{
    "en": 'Cities that you expected to travel?',
    "cn": '预计前往城市'
  },
  dsform54:{
    "en": 'Address expected to stay in the United States for the first night',
    "cn": '预计第一晚停留的详细地址'
  },
  dsform55:{
    "en": 'Address expected to stay in the United States for the first night',
    "cn": '预计第一晚停留的详细地址'
  },
  dsform56:{
    "en": 'Contact Number of the above address',
    "cn": '停留地址的联系方式'
  },
  dsform57:{
    "en": 'Name of Fellow traveler',
    "cn": '同行人姓名'
  },
  dsform58:{
    "en": 'Relationship with the Fellow traveler',
    "cn": '同行人与你的关系'
  },
  dsform59:{
    "en": 'Emergency contact - Name',
    "cn": '出行紧急联系人姓名（不能是同行人）'
  },
  dsform60:{
    "en": 'Emergency contact - Phone',
    "cn": '出行紧急联系人电话（不能是同行人）'
  },
  dsform61:{
    "en": 'Emergency contact - Email',
    "cn": '出行紧急联系人邮箱（不能是同行人）'
  },
  dsform62:{
    "en": 'Who pay for your trip (And Relationship)',
    "cn": '行程付款人（若自付则填自己）'
  },
  dsform63:{
    "en": 'Whether you have relatives in US',
    "cn": '是否在美国有亲属 (提供名字/关系/身份）'
  },
  dsform67:{
    "en": 'Have you been to US',
    "cn": '您是否到过美国？'
  },
  dsform68:{
    "en": 'If yes, fillout the last 5 visits (Date / Duration)',
    "cn": '若去过，请列出最近5次访美记录 (日期 / 停留天数）'
  },
  dsform69:{
    "en": 'Previous US visa issued place and visa type (B/F/J/H etc.)',
    "cn": '旧美签签发地 / 类型'
  },
  dsform70:{
    "en": 'Previous US visa issued date',
    "cn": '旧美签签发时间'
  },
  dsform71:{
    "en": 'Previous US visa number (8 digits)',
    "cn": '旧美签签证号（8位）'
  },
  dsform72:{
    "en": 'Number of rejections',
    "cn": '美签拒签次数'
  },
  dsform73:{
    "en": 'Date of refusal',
    "cn": '拒签日期 年/月/日'
  },
  dsform74:{
    "en": 'Refusal type',
    "cn": '拒签类型'
  },
  dsform75:{
    "en": 'Has your US visa been lost or revoked?',
    "cn": '美签是否丢失或被吊销过'
  },
  dsform76:{
    "en": 'Have you ever applied for an American immigration visa?',
    "cn": '是否曾经申请过美国移民签证？'
  },
  dsform77:{
    "en": 'Have you ever been banned from entering the United States',
    "cn": '是否曾经被禁止进入美国境内？'
  },
  dsform78:{
    "en": 'Did you study in American public primary or secondary schools after 11/30/1996?',
    "cn": '是否在1996年11月30日后以学生身份在美国公立小学、公立中学学习？'
  },
  dsform79:{
    "en": 'List all countries you have entered in the past five years.',
    "cn": '列出在过去五年内您曾进入的所有国家及年份'
  },
  dsform80:{
    "en": 'Have you ever lost your passport?',
    "cn": '是否遗失过护照？'
  },
  dsform81:{
    "en": 'Lost passport number and explain how it lost',
    "cn": '若失过护照, 请提供护照号和如何丢失'
  },
  dsform82:{
    "en": 'Workplace（1）Name',
    "cn": '工作单位（1）名称'
  },
  dsform83:{
    "en": 'Workplace（1）Address & Zip code',
    "cn": '工作单位（1）详细地址及邮编'
  },
  dsform84:{
    "en": 'Workplace（1）Telphone',
    "cn": '工作单位（1）联系电话'
  },
  dsform85:{
    "en": 'Workplace（1）job position',
    "cn": '工作单位（1）任职职务'
  },
  dsform86:{
    "en": 'Workplace（1）supervisor name',
    "cn": '工作单位（1）主管姓名'
  },
  dsform87:{
    "en": 'Workplace（1）working duration (from - to)',
    "cn": '工作单位（1）工作时长'
  },
  dsform88:{
    "en": 'Workplace（2）Name',
    "cn": '工作单位（2）名称'
  },
  dsform89:{
    "en": 'Workplace（2）Address & Zip code',
    "cn": '工作单位（2）详细地址及邮编'
  },
  dsform90:{
    "en": 'Workplace（2）Telphone',
    "cn": '工作单位（2）联系电话'
  },
  dsform91:{
    "en": 'Workplace（2）job position',
    "cn": '工作单位（2）任职职务'
  },
  dsform92:{
    "en": 'Workplace（2）supervisor name',
    "cn": '工作单位（2）主管姓名'
  },
  dsform93:{
    "en": 'Workplace（2）working duration (from - to)',
    "cn": '工作单位（2）工作时长'
  },
  dsform94:{
    "en": 'Have you ever been in the army?',
    "cn": '是否参过军？'
  },
  dsform95:{
    "en": 'School（1）Name',
    "cn": '学校（1）名称'
  },
  dsform96:{
    "en": 'School（1）Name & Zip code',
    "cn": '学校（1）详细地址及邮编'
  },
  dsform97:{
    "en": 'School（1）Telphone',
    "cn": '学校（1）联系电话'
  },
  dsform98:{
    "en": 'School（1）Major',
    "cn": '学校（1）所学专业'
  },
  dsform99:{
    "en": 'Study（1）duration (from - to)',
    "cn": '学校（1）起止月份'
  },
  dsform100:{
    "en": 'School（2）Name',
    "cn": '学校（2）名称'
  },
  dsform101:{
    "en": 'School（2）Name & Zip code',
    "cn": '学校（2）详细地址及邮编'
  },
  dsform102:{
    "en": 'School（2）Telphone',
    "cn": '学校（2）联系电话'
  },
  dsform103:{
    "en": 'School（2）Major',
    "cn": '学校（2）所学专业'
  },
  dsform104:{
    "en": 'Study（2）duration (from - to)',
    "cn": '学校（2）起止月份'
  },
  dsform105:{
    "en": 'Extra Information you want to provide',
    "cn": '额外信息需要补充'
  },
  dsform106:{
    "en": '- Please input N/A if no answer',
    "cn": '- 请仔细检查数字和英文字母的大小写'
  },
  dsform107:{
    "en": '- Please input N/A if no answer',
    "cn": '- 请确保必填的选项不能为空'
  },
  dsform108:{
    "en": '- Please input N/A if no answer',
    "cn": '- 无答案或不确定的项填 无 或 不确定'
  },
} 