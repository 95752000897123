import React, {useState,useEffect} from 'react'
import {Button,Collapse,Modal,Radio, Space, InputNumber, Checkbox, Alert} from 'antd'
import './index.less'
import img_background from '../../asset/img/plan/bg.png'
// import img_background_mobile from '../../asset/img/resume/resume_mobile.svg'
import img_5Percent from '../../asset/img/plan/5percent.png'
import img_20percent from '../../asset/img/plan/20percent.png'
import img_75Percent from '../../asset/img/plan/75percent.png'
import img_question from '../../asset/img/plan/question_boy.png'
import img_hightlight from '../../asset/img/plan/hightlight.png'
import img_h01 from '../../asset/img/plan/h01.png'
import img_h02 from '../../asset/img/plan/h02.png'
import img_h03 from '../../asset/img/plan/h03.png'
import img_h04 from '../../asset/img/plan/h04.png'
import img_about from '../../asset/img/plan/about.png'
import img_line from '../../asset/img/resume/h04.jpg'
import wechat from '../../asset/img/wechat.png'
import rosalyn_wechat from '../../asset/img/rosalyn_wechat.jpg'
// import img_greyplus from '../../asset/img/offerplus/plus_grey.png'
import img_target from '../../asset/img/offerplus/st-target.svg'
import img_bag from '../../asset/img/offerplus/bag.svg'
import img_people3 from '../../asset/img/offerplus/people-3.svg'
import img_check from '../../asset/img/resume/check.png'
import img_enroll_step from '../../asset/img/intro_enroll_step.svg'
// import img_logo1 from '../../asset/img/resume/Icon1.png'
import img_logo3 from '../../asset/img/plan/info.png'
import img_logo5 from '../../asset/img/plan/question.png'
import Header from '../../components/header'
import Footer from '../../components/footer'
import PlanConsultQrcode from '../../components/plan/consult_qrcode'
import PayQrcode from '../../components/plan/pay_qrcode'
import quanfangwei from '../../asset/img/quanfangwei.png'
import { loadStripe } from '@stripe/stripe-js';
import { ThunderboltOutlined, CaretRightOutlined,CreditCardOutlined, WechatOutlined}from '@ant-design/icons';
import { useLocaleContext } from '../../utils/context'
import { Text } from '../../utils/locale'
import { Helmet } from 'react-helmet';

const stripePromise = loadStripe('pk_live_51N4wIoHrsABESZ3IQ8MEyhTqkPNefCtqGIvnecGZgoll0Sb6p88tOAUSUPKjOJWg3Lxmd5fKGGYYM8qqxwAjleDK00yj5IVGby');
export default function Index() {
    const { Panel } = Collapse;  
    const [refVisibleConsult, setRefVisibleConsult] = useState(false)
    const [refVisiblePayQrCode, setRefVisiblePayQrCode] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [planSelect, setPlanSelect] = useState(1);
    const [num, setNum] = useState(1);
    const [ count, SetCount] = useState(3)
    const { locale } = useLocaleContext()
    const [checkUnderstand, setCheckUnderstand] = useState(false);
    const [alert, SetAlert] = useState(false);
    const [payMethod, SetPayMethod] = useState('');
    const [showPayMenthod, SetShowPayMethod] = useState(false);

    const cancelConfirm = () => {
        console.log('cancel')
    }
    const hiddeTidoLogo = (iframeContent) => {
        console.log('点击了')
        setTimeout(()=> {
            var element = document.querySelector('iframe[title="Tidio Chat"]');
            var iframeContent = element.contentDocument || element.contentWindow.document;
            var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
            console.log(elementChild)
            // const body = iframeContent.querySelector('body');
            if (elementChild) {
                elementChild.parentNode.style.display = 'none'
            }
        },500)
    }

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                var element = document.querySelector('iframe[title="Tidio Chat"]');
                // console.log(element)
                if (element === null || element === undefined) {
                    SetCount(count-1)
                } else {
                    console.log(element)
                    var iframeContent = element.contentDocument || element.contentWindow.document;
                    console.log(iframeContent)
                    // element.addEventListener('click',hiddeTidoLogo)
                    const body = iframeContent.querySelector('body');
                    body.addEventListener('click',hiddeTidoLogo)
                }
                
            }
        }, 2000);
    },[count])
  
    const openConsult = e => {
        setRefVisibleConsult(true)
    }

    const changeVisibleConsult = e => {
        setRefVisibleConsult(e)
    }

    const changeVisiblePayQrCode = e => {
        setRefVisiblePayQrCode(e)
    }

    const showModal = () => {
        console.log(isModalOpen)
        setIsModalOpen(true);
    };
    
    const handleOk = () => {
        if (checkUnderstand === false) {
            SetAlert(true)
            return null
        } else {
            SetAlert(false)
            // handleClick();
            SetShowPayMethod(true)
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onPlanChange = (e) => {
        console.log('radio checked', e.target.value);
        setPlanSelect(e.target.value);
      };

    const handleClick = async (event) => {
        let successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqyy"
        let price = 'price_1PRg1WHrsABESZ3I7hpbRO76'
        if (planSelect === 1) {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqyy"
            price = 'price_1PRg1WHrsABESZ3I7hpbRO76'
        }
        if (planSelect === 2) {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqqfw"
            // price = 'price_1NQ1X0HrsABESZ3Ixf6yH3Qf'
            price = 'price_1PV0LwHrsABESZ3IDHvs85qK'
        }
        if (planSelect === 3) {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqqfwyy"
            // price = 'price_1NQ1X0HrsABESZ3Ixf6yH3Qf'
            price = 'price_1PV0NVHrsABESZ3I7GERgcQf'
        }

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{
            price: price, // Replace with the ID of your price
            quantity: num,
          }],
          mode: 'payment',
          successUrl: successUrl,
          cancelUrl:  window.location.origin+'/payresult/cancel?type=1',
        });
        setIsModalOpen(false);
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
    };

    const onNumChange = (value) => {
        setNum(value)
    }

    const onCheckChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
        setCheckUnderstand(e.target.checked)
        SetAlert(!e.target.checked)
    }

    const openPolicy = () => {
        window.open("https://www.caex-consulting.com/agreement")
    }
    
    const choosePayMethod = (menthod) => {
        if ( payMethod === '') {
            SetPayMethod(menthod)
        } else {
            if (payMethod === menthod) {
                SetPayMethod('')
            }
            else {
                SetPayMethod(menthod)
            }
        }
    }

    const confirmToPay = () => {
        if (payMethod === 'online') {
            handleClick()
        } else {
            setRefVisiblePayQrCode(true)
        }
        SetShowPayMethod(false)
    }

    const refuseToPay = () => {
        SetShowPayMethod(false)
        SetPayMethod('')
    }
    return (
    <React.Fragment>
        <Helmet>
            <title>预约申请服务</title>
            <meta
            name="description"
            content="专业团队针对性计划安排，快人一步拿到签证，科学安排面签计划"
            />
            <meta
            name="keywords"
            content="美签预约，美签免面签申请，美签申请，美签代办，美签加急"
            />
        </Helmet>
        <Header/>
        <PlanConsultQrcode refVisible={refVisibleConsult} changeVisible={changeVisibleConsult}/>
        <PayQrcode refVisible={refVisiblePayQrCode} changeVisible={changeVisiblePayQrCode}/>
        <Modal
            width={620}
			visible={showPayMenthod}
            // onCancel={onClose}
            centered
            closable={false}
            footer={payMethod !== '' ? <Button type="primary" style={{width: "100%"}} onClick={e => confirmToPay()}>前往支付</Button> : <Button onClick={e => refuseToPay()}>返回</Button>}
            okText="前往支付"
            cancelText="返回"
            // prefixCls= 'main-ant-modal'
            className="popup_offerplus"
            >
            <div className="container">
                <div className="methodDes"><CaretRightOutlined /> 请点击选一种方式支付</div>
                <div className="pay_menthod_wrap">
                    <div className={payMethod === "online" ? "left_click": "left"}  onClick={ e => choosePayMethod('online')}>
                        <div className="methodTitle"><CreditCardOutlined className="credit-card"/>方式一</div>
                        <p className="formAlert">银行 Debit / Credit /  Pay</p>
                    </div> 
                    <div className={payMethod === "offline" ? "right_click": "right"} onClick={ e => choosePayMethod('offline')}>
                        <div className="methodTitle"><WechatOutlined className="wechat"/>方式二</div>
                        <p className="formAlert2">微信支付 RMB（汇率转换）</p>
                    </div>
                </div>
            </div>
		</Modal>
        <Modal
			width={400}
			visible={isModalOpen}
			onCancel={handleCancel}
			onOk={handleOk}
            title={ <span style={{fontWeight:700}}><ThunderboltOutlined style={{fontSize: 18,marginRight:5}}/>请选择服务类型</span>}
            centered={true}
            okText="选择支付方式"
            cancelText="返回"
			>
            <div>
            <span>服务：</span>
                <Radio.Group onChange={onPlanChange} value={planSelect}>
                    <Space direction="vertical">    
                        <Radio value={1}>加急预约</Radio>
                        <Radio value={2}>专属定制申请</Radio>
                        {/* <Radio value={2}>专属定制申请 - <strong style={{color: '#ce3636'}}>限时$200</strong></Radio> */}
                        <Radio value={3}>安心全方位</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className='num-count'>
                <span>数量：</span><InputNumber min={1} max={9} defaultValue={1} onChange={onNumChange} />
            </div>
            <div className='num-count'>
                <span>备注：系统根据实时汇率自动转换<strong> CAD/加币 </strong>结算</span>
            </div>
            <div className='num-count'>
                <Checkbox onChange={onCheckChange}>I have read and understood the <a href="javascript:void(0);" onClick={ e => openPolicy()}>Privacy Policy and the Terms of Use</a></Checkbox>
            </div>
            { checkUnderstand === false && alert === true && <Alert message="请勾选 Privacy Policy and the Terms of Use" type="warning" showIcon closable={false} />}
        </Modal>
        <div className="pkg_resume">
            <div className="btn_floating"> 
                <h4>{Text.index171[locale]}</h4>
                <img
                    src={rosalyn_wechat}
                    className="img_wechat_floating"
                    alt=""></img>
            </div>
            {/* 固定TOP NAV */}
            {/* Header Section */}
            <div className="header_resume">
                <div className="header_content">
                    <div className="header_des">
                        <h5 className="des_first">{Text.index95[locale]}</h5>
                        <p>{Text.index96[locale]}</p>
                        <Button
                            onClick={e => openConsult(e)}
                            // onClick={handleClick}
                            data-seed="pkg-resume-buy-top"
                            className="btn_consult"
                            >{Text.index97[locale]}</Button>
                    </div>
                    <img className="background_img" src={img_background} alt=""></img>
                    <img className="backgound_img_mobile" src={img_background} alt=""></img>
                </div>
            </div>

            <div className="pcontent">
                {/* 项目目标banner */}
                <div className="package_details">
                    <div className="details_content">
                        <div className="package_detail">
                            {/* 不需要 css用border-left就实现 <Icon type="line" className="icon_line}/> */}
                            <img src={img_target} alt=""></img>
                            <p className="detail_title">{Text.index98[locale]}</p>
                            <p className="detail_des">{Text.index99[locale]}</p>
                        </div>
                        <div className="package_detail">
                            {/* <Icon type="line" className="icon_line}/> */}
                            <img src={img_bag} alt=""></img>
                            <p className="detail_title">{Text.index100[locale]}</p>
                            <p className="detail_des">{Text.index101[locale]}</p>
                        </div>
                        <div className="package_detail">
                            {/* <Icon type="line" className="icon_line}/> */}
                            <img src={img_people3} alt=""></img>
                            <p className="detail_title">{Text.index102[locale]}</p>
                            <p className="detail_des">{Text.index103[locale]}</p>
                        </div>

                    </div>
                </div>
                {/* 关于 */}
                <div className="diagnoseContent">
                    <div className="aboutResume">
                        <div className="advantage">
                            <img src={img_about} className="img_hightlight" alt=""/>
                            <div className="advantageDes">
                                <h5>{Text.index104[locale]}</h5>
                                <p>{Text.index105[locale]}</p>
                            </div>
                        </div>
                        <div className="rusume_des">
                            <h2 className="first_title">{Text.index106[locale]}</h2>
                            {/* <img className="title_line" src={img_grey_line} alt=""></img> */}
                            <img className="des_line" src={img_line} alt=""></img>
                            <div className="description">
                                <div className="fact">
                                    <img src={img_20percent} alt=""></img>
                                    <h2 className="number">20</h2>
                                    <h5 className="unit">%</h5>
                                    <p>{Text.index107[locale]}</p>
                                </div>

                                <div className="fact">
                                    <img src={img_5Percent} alt=""></img>
                                    <h2 className="number">5</h2>
                                    <h5 className="unit">个</h5>
                                    <p>{Text.index108[locale]}</p>
                                </div>

                                <div className="fact">
                                    <img src={img_75Percent} alt=""></img>
                                    <h2 className="number_3">75</h2>
                                    <h5 className="unit_3">%</h5>
                                    <p>{Text.index109[locale]}</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="desContent">
                        <img src={img_question} alt=""></img>
                        <p>{Text.index110[locale]}<br/>
                        {Text.index111[locale]}<br/>
                        {Text.index112[locale]}<br/>
                        {Text.index113[locale]}</p>
                    </div>
                    {/* <p className="desContentBottom">自己做调研，辨别信息可用性需要反复琢磨，不如交给经验 丰富的签证助手帮你解决，精准高效地指出问题，锁定心仪面试！</p> */}

                    <div className="highlight">
                        <div className="advantage">
                            <img src={img_hightlight} className="img_hightlight" alt=""/>
                            <div className="advantageDes">
                                <h5>{Text.index114[locale]}</h5>
                                <p>{Text.index115[locale]}</p>
                            </div>
                        </div>
                        <div className="itemContain">
                            <div className="itemRow">
                                <div className="item">
                                    <img src={img_h01} alt=""/>
                                    <div className="des">
                                        <h2>{Text.index116[locale]}</h2>
                                        <div className="con">{Text.index117[locale]}</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src={img_h02} alt=""/>
                                    <div className="des">
                                        <h2>{Text.index118[locale]}</h2>
                                        <div className="con">{Text.index119[locale]}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="itemRow">
                                <div className="item">
                                    <img src={img_h03} alt={img_h03}/>
                                    <div className="des">
                                        <h2>{Text.index120[locale]}</h2>
                                        <div className="con">{Text.index121[locale]}</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src={img_h04} alt={img_h04}/>
                                    <div className="des">
                                        <h2>{Text.index122[locale]}</h2>
                                        <div className="con">{Text.index123[locale]}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="itemRow">
                                <div className="item">
                                    <img src={img_h04} alt={img_h04}/>
                                    <div className="des">
                                        <h2>{Text.index122_2[locale]}</h2>
                                        <div className="con">{Text.index123_2[locale]}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='quanfangwei'>
                    <img src={quanfangwei} alt="" />
                </div>
                {/* 项目详情到最后*/}
                <div className="details" >
                {/* <div className="details" ref={detailRef} > */}
                    <div className="advantage">
                        <img src={img_logo3} className="img_hightlight" alt=""/>
                        <div className="advantageDes">
                            <h5>{Text.index124[locale]}</h5>
                            <p>{Text.index125[locale]}</p>
                        </div>
                    </div>

                    <div className="content">
                        <div name="forEdge">
                            <table className="table_main">
                                <thead className="normal">
                                    <tr>
                                        <th></th>
                                        <th>
                                            <div className="headBlueLight">{Text.index126[locale]}</div>
                                        </th>
                                        <th>
                                            <div className="headBlue">{Text.index127[locale]}</div>
                                        </th>
                                        <th>
                                            <div className="headBlue">{Text.index128[locale]}</div>
                                        </th>
                                    </tr>
                                </thead>
                                <thead className="mobile">
                                    <tr>
                                        <th></th>
                                        <th><div className="headBlueLight">{Text.index129[locale]}</div></th>
                                        <th><div className="headBlue">{Text.index130[locale]}</div></th>
                                        <th><div className="headBlue">{Text.index131[locale]}</div></th>
                                    </tr>
                                </thead>
                                <tbody className="tbody">
                                    <tr className="empty">
                                        <td></td>
                                        <td className="grey"></td>
                                        <td className="grey-high"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">{Text.index132[locale]}</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td className="grey-high"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                            {Text.index133[locale]}</div>
                                            {/*  <div className="con">Present your story in a more compelling way with Indust
    * ry professionals experienced in recruiting. Highlight your strength and make 
    * the keywords stand out</div> 
    */
                                            }
                                            {/* <div className="time">60min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td className="grey-high">
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                            {Text.index134[locale]}</div>
                                            {/*  <div className="con">Add some cosmetics from our language experts and say th
    * ings the right way</div> 
    */
                                            }
                                            {/* <div className="time">30min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td className="grey-high">
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                            {Text.index135[locale]}</div>
                                            {/*  <div className="con">Line-by-line editing to make your resume reach its grea
    * test potential and show the real impact</div> 
    */
                                            }
                                            {/* <div className="time">60min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td className="grey-high">
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                            {Text.index136[locale]}</div>
                                            {/*  <div className="con">Line-by-line editing to make your resume reach its grea
    * test potential and show the real impact</div> 
    */
                                            }
                                            {/* <div className="time">60min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td className="grey-high">
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div className="con">{Text.index137[locale]}</div>
                                            {/*  <div className="con">On personalized process navigation & Weetok Sharer reco
    * mmendation</div> 
    */
                                            }
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            {/* <img src={img_check} alt='check' className="imgCheck"></img> */}
                                        </td>
                                        <td className="grey-high">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">{Text.index138[locale]}</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td className="grey-high">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">{Text.index139[locale]}</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td className="grey-high">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">{Text.index140[locale]}</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td className="grey-high">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">{Text.index141[locale]}</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td className="grey-high">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        {/* <td className="grey"></td>
                                        <td className="time">60分钟</td> */}
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_bracket">{Text.index142[locale]}</div>
                                        </td>
                                        <td className="grey">
                                            {/* <img src={img_check} alt='check' className="imgCheck"></img> */}
                                        </td>
                                        <td className="grey-high">
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_bracket">{Text.index143[locale]}</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td className="grey-high">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr className="empty">
                                        <td></td>
                                        <td className="grey"></td>
                                        <td className="grey-high"></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            name="forEdge">
                            <table className="table_price">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>{Text.index144[locale]}</div>

                                            {/* {
                                                Tools.getRegion() === 'en-GB' && <div className="ukPayDes">
                                                        * 英镑价格根据参考汇率换算<br/>
                                                        * 付款时需以美元支付
                                                    </div>
                                            } */}

                                        </td>
                                        <td className="grey">
                                            <div className="regularPrice">
                                                <span id="global.price">
                                                {Text.index145[locale]}
                                                </span>
                                                {/* <FormattedMessage
                                                    id="global.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        price: buyData[0].origin_price,
                                                        price_foreign: buyData[0].origin_price_GBP
                                                    }}/> */}
                                            </div>
                                            <div className="realPrice">
                                                <span id="pkgresume.price">
                                                {Text.index146[locale]}
                                                </span>
                                                {/* <FormattedMessage
                                                    id="pkgresume.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        span: (...chunks) => (
                                                            <span key="1" className="priceDollar">{chunks}</span>
                                                        ),
                                                        price: buyData[0].price,
                                                        price_foreign: buyData[0].price_GBP
                                                    }}/> */}
                                            </div>
                                        </td>
                                        <td className="grey-high">
                                            <div className="regularPrice">
                                            <span id="global.price">
                                            {Text.index147[locale]}
                                                </span>
                                                {/* <FormattedMessage
                                                    id="global.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        price: buyData[1].origin_price,
                                                        price_foreign: buyData[1].origin_price_GBP
                                                    }}/> */}
                                            </div>
                                            <div className="realPrice">
                                                <span id="pkgresume.price">
                                                {Text.index148[locale]}
                                                </span>
                                                {/* <FormattedMessage
                                                    id="pkgresume.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        span: (...chunks) => (
                                                            <span key="1" className="priceDollar">{chunks}</span>
                                                        ),
                                                        price: buyData[1].price,
                                                        price_foreign: buyData[1].price_GBP
                                                    }}/> */}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="regularPrice">
                                            <span id="global.price">
                                            {Text.index149[locale]}
                                                </span>
                                                {/* <FormattedMessage
                                                    id="global.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        price: buyData[1].origin_price,
                                                        price_foreign: buyData[1].origin_price_GBP
                                                    }}/> */}
                                            </div>
                                            <div className="realPrice">
                                                <span id="pkgresume.price">
                                                {Text.index150[locale]}
                                                </span>
                                                {/* <FormattedMessage
                                                    id="pkgresume.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        span: (...chunks) => (
                                                            <span key="1" className="priceDollar">{chunks}</span>
                                                        ),
                                                        price: buyData[1].price,
                                                        price_foreign: buyData[1].price_GBP
                                                    }}/> */}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        {/* <table className="table_buy">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <Button
                                            data-seed="pkg-resume-buy-2"
                                            className={`="BuyButton} ${ "btn1"}`}
                                            disabled={btn1Disabled}
                                            onClick={() => openPay(`${buyData[0].id}`)">{btnText0}</Button>
                                    </td>
                                    <td>
                                        <Button
                                            data-seed="pkg-resume-buy-3"
                                            className={`="BuyButton} ${ "btn2"}`}
                                            disabled={btn2Disabled}
                                            onClick={() => openPay(`${buyData[1].id}`)">{btnText1}</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}

                        {/* {
                            Tools.getRegion() === 'en-GB' && <div className="ukPayDesMobile">
                                    * 英镑价格根据参考汇率换算<br/>
                                    * 付款时需以美元支付
                                </div>
                        } */}
                    </div>
                </div>
                
                <Button
                        // onClick={e => openConsult(e)}
                        onClick={showModal}
                        type="primary"
                        data-seed="pkg-resume-consult"
                        className="consultBtn">{Text.index151[locale]}

                </Button>
            
                <div className="flowPath">
                    <p className="enroll_title">{Text.index152[locale]}
                    </p>
                    <p className="enroll_des">{Text.index153[locale]}</p>
                    <div className="enroll_step_con">
                        <div className="enroll_step1">{Text.index154[locale]}<br/>{Text.index155[locale]}</div>
                        <img src={img_enroll_step} alt="" className="img_enroll_step"/>
                        <div className="enroll_step2">{Text.index156[locale]}<br/>{Text.index157[locale]}</div>
                        <img src={img_enroll_step} alt="" className="img_enroll_step"/>
                        <div className="enroll_step3">{Text.index158[locale]}</div>
                        <img src={img_enroll_step} alt="" className="img_enroll_step"/>
                        <div className="enroll_step4">
                            <span>{Text.index159[locale]}</span>
                        </div>
                    </div>
                </div>
                {/* 简历指南 */}
            </div>
            <div className="faq">
                <div className="advantage">
                    <img src={img_logo5} className="img_hightlight" alt=""/>
                    <div className="advantageDes">
                        <h5>{Text.index160[locale]}</h5>
                    </div>
                </div>
                <Collapse bordered={false} className="faq_collapse">
                    <Panel header={<div data-seed="pkg-resume-faq01">{Text.index161[locale]}</div>}>
                        <p className="faq_answer">{Text.index162[locale]}</p>
                    </Panel>
                    <Panel header={<div data-seed="pkg-resume-faq02">{Text.index163[locale]}</div>}>
                        <p className="faq_answer">{Text.index164[locale]}</p>
                    </Panel>
                    <Panel
                        header={<div data-seed="pkg-resume-faq03"> {Text.index165[locale]}</div>}>
                        <p className="faq_answer">{Text.index166[locale]}</p>
                    </Panel>
                    <Panel
                        header={<div data-seed="pkg-resume-faq04"> {Text.index167[locale]}</div>}>
                        <p className="faq_answer">{Text.index168[locale]}</p>
                    </Panel>
                    <Panel
                        header={<div data-seed="pkg-resume-faq04"> {Text.index169[locale]}</div>}>
                        <p className="faq_answer">{Text.index170[locale]}</p>
                    </Panel>
                </Collapse>
            </div>
        </div>
    <Footer/>
    </React.Fragment>
  )
}