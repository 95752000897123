import React, {useState, useEffect} from 'react'
import { Tools, Storage } from '../../utils/tools'
import { Menu, Result, Table, Tag, Modal, Descriptions, Badge } from 'antd';
import { Link } from 'react-router-dom'
import './index.less'
import { getSession } from '../../api/user'
import { TagsOutlined, AlertOutlined, UserOutlined, LogoutOutlined, ClusterOutlined, ScheduleFilled, EnvironmentOutlined, RocketOutlined,UsergroupAddOutlined,CheckCircleOutlined, MoneyCollectOutlined  } from '@ant-design/icons';
import Header from '../../components/header'
import Footer from '../../components/footer'
// WebWorker helper class
import WebWorker from '../../utils/webWorker';
// Your web worker
import LoopWorker from '../../worker/loopWorker';
// import tableLogo from '../../asset/img/table-logo.png';
import vancover from '../../asset/img/van_1.png';
import calgary from '../../asset/img/cal_1.png';
import toronto from '../../asset/img/tor_1.png';
import ottawa from '../../asset/img/ott_1.png';
import montreal from '../../asset/img/mon_1.png';
import quebec from '../../asset/img/que_1.png';
import halifax from '../../asset/img/hal_1.png';
import frontpage from '../../asset/img/frontpage.png';
import frontpage_mb from '../../asset/img/frontpage-mb.png';
import step1 from '../../asset/img/step1.png';
import arrow from '../../asset/img/arrow.png';
import flow_bg from '../../asset/img/flow-bg.png'
import f1 from '../../asset/img/f1icon.png'
import b1b2 from '../../asset/img/b1b2icon.png'
import h1b from '../../asset/img/h1bicon.png'
import vector from '../../asset/img/vector.png'
import vector2 from '../../asset/img/vector2.png'
import quanfangwei from '../../asset/img/quanfangwei.png'
import { useLocaleContext } from '../../utils/context'
import { Text } from '../../utils/locale'
import { Helmet } from 'react-helmet';
// console.log('Init worker...')

// // Worker init
// const workerInstance = new WebWorker(LoopWorker) 


// // Listening for messages from worker
// workerInstance.addEventListener("message", e => {

//   console.log('[MAIN] MSG FROM WORKER: ', e.data)


// }, false)


// workerInstance.postMessage("Hi, worker! I'm main thread!")
export default function Index() {
    const [ userInfo, SetUserInfo ] = useState(undefined)
    const [ mobile, SetMobile ] = useState(false)
    const [ consularVisible, SetConsularVisible ] = useState(false)
    const [ consularModalData, SetConsularModalData] = useState({})
    const [ count, SetCount] = useState(3)
    const { locale } = useLocaleContext()


    const token = Tools.getToken()
    console.log('Init worker...')
    const workerInstance = new WebWorker(LoopWorker);
    
    useEffect(() => {
        const country = Storage.getLocal('country')
        // console.log(country)
        if (country === false || country === null) {
            window.location.replace(window.location.origin+"/country/select")    
        }
        isMobile()
        workerInstance.postMessage("bar");  
        workerInstance.onmessage = function(evt) {
            // console.log('主收'+evt.data)
            console.log(evt)
        }
    }, [])
    

    const hiddeTidoLogo = (iframeContent) => {
        console.log('点击了')
        setTimeout(()=> {
            var element = document.querySelector('iframe[title="Tidio Chat"]');
            var iframeContent = element.contentDocument || element.contentWindow.document;
            var elementChild = iframeContent.querySelector('a[aria-label="Powered by Tidio."]');
            console.log(elementChild)
            // const body = iframeContent.querySelector('body');
            if (elementChild) {
                elementChild.parentNode.style.display = 'none'
            }
        },500)
    }

    useEffect(() => {
        setTimeout(() => {
            if (count > 0) {
                var element = document.querySelector('iframe[title="Tidio Chat"]');
                // console.log(element)
                if (element === null || element === undefined) {
                    SetCount(count-1)
                } else {
                    console.log(element)
                    var iframeContent = element.contentDocument || element.contentWindow.document;
                    console.log(iframeContent)
                    // element.addEventListener('click',hiddeTidoLogo)
                    const body = iframeContent.querySelector('body');
                    body.addEventListener('click',hiddeTidoLogo)
                }
                
            }
        }, 2000);
    },[count])

    useEffect(() => {
        if(token !== '') {
            getSession({token}).then(res => {
                if (res.status_code === 200) {
                    SetUserInfo(res.data)
                }
            })
        }else{
            SetUserInfo(null)
        }
    }, [token])

    const handleLogout = () => {
        //清除token
        console.log('退出了')
        Tools.userLogout()
        SetUserInfo(null)
    }

    const isMobile = () => {
        SetMobile(/(iPhone|iPod|Android|SymbianOS|Windows Phone)/i.test(navigator.userAgent))
    }

    const menu = (
        <Menu className="menu">
          <Menu.Item  key="0" icon={<UserOutlined />} >
            <Link to="/user/profile">我的账户</Link>
          </Menu.Item>
          {/* <Menu.Item key="1">
            <a href="http://www.taobao.com/">2nd menu item</a>
          </Menu.Item> */}
          <Menu.Divider />
          <Menu.Item  key="3" icon={<LogoutOutlined />}>
              <span onClick={() =>handleLogout()}>退出登录</span>
          </Menu.Item>
        </Menu>
    );
    

    const columns_mobile = [
        {
            title: "City",
            dataIndex: "name",
            key: "name",
            width: "15%",
            render: (text) => <b>{text}</b>,
        },
        {
            title: "Earliest Date",
            dataIndex: "time",
            width: "30%",
            key: "etime",
            render: (text) => <a> {text} </a>,
        },
    ]

    const columns = [
        {
          title: "City",
          dataIndex: "name",
          key: "name",
          width: "5%",
          render: (text) => <b>{text}</b>,
        },
        {
          title: "Available Visa Type",
          dataIndex: "age",
          key: "age",
          width: "15%"
        },
        {
          title: "Address",
          dataIndex: "address",
          width: "18%",
          key: "address"
        },
        {
          title: "Earliest Date",
          dataIndex: "time",
          width: "8%",
          key: "etime",
          render: (text) => <a> {text} </a>,
        },
        {
          title: "Update Date",
          dataIndex: "utime",
          width: "12%",
          key: "utime"
        },
        {
          title: "Estimate Next Release",
          dataIndex: "etime",
          width: "12%",
          key: "etime",
          render: (tags) => (
            <Tag>
                  {tags}
            </Tag>
          )
        }
    ];

    const utime = new Date().getFullYear()+ '-' + (parseInt(new Date().getMonth())+ 1).toString()+ '-' + new Date().getDate() + ' '+new Date().getHours()+':'+new Date().getMinutes()+':'+new Date().getSeconds()

    const time_pool = {
        'calgary': [
            '2027-02-16', '2027-04-17', '2027-01-13', '2027-02-20', '2026-12-22', '2026-10-24', '2027-04-24'
        ],
        'halifax': [
            '-', '-', '-', '-', '-', '-', '-'
        ],
        'montreal': [
            '-', '-', '-', '-', '-', '-', '-'
        ],
        'ottawa': [
            '-', '-', '-', '-', '-', '-', '-'
        ],
        'quebec': [
            '-', '-', '-', '-', '-', '-', '-'
        ],
        'toronto': [
            '2027-01-14', '2026-11-28', '2026-11-26', '2027-01-15', '2026-12-14', '2027-01-22', '2026-12-21' 
        ],
        'vancouver': [
            '2026-11-27', '2026-11-20', '2026-12-04', '2026-12-11', '2027-01-14', '2027-01-07', '2026-11-13' 
        ],
    }
    
    const getEtime = (city) => {
    const index = new Date().getDay()
    return time_pool[city][index]
    }
    
    const data = [
    {
        key: 1,
        name: "Calgary",
        age: "F1 / F2 / B1 / B2 / H1B / H4 / J1 / J2 / L1 / L2 / O1 /O2 / O3",
        address: "615 Macleod Trail, SE Suite 1000 Calgary, AB, T2G 4T8 Canada",
        time: getEtime('calgary'),
        etime: '3 days',
        utime: utime
    },
    {
        key: 2,
        name: "Halifax",
        age: "F1 / F2 / B1 / B2 / H1B / H4 / J1 / J2 / L1 / L2 / O1 /O2 / O3",
        address: "Suite 904, Purdy's Wharf Tower II 1969 Upper Water Street Halifax, NS, Nova Scotia, B3J 3R7 Canada",
        time: getEtime('halifax'),
        etime: '-',
        utime: utime
    },
    {
        key: 3,
        name: "Montreal",
        age: "F1 / F2 / B1 / B2 / H1B / H4 / J1 / J2 / L1 / L2 / O1 /O2 / O3",
        address: "1134 Saint-Catherine St. West Montréal, QC, Québec, H3B 1H4 Canada",
        time: getEtime('montreal'),
        etime: '-',
        utime: utime
    },
    {
        key: 4,
        name: "Ottawa",
        age: "F1 / F2 / B1 / B2 / H1B / H4 / J1 / J2 / L1 / L2 / O1 /O2 / O3",
        address: "490 Sussex Drive Ottawa, ON, Ontario, K1N 1G8 Canada",
        time: getEtime('ottawa'),
        etime: '-',
        utime: utime
    },
    {
        key: 5,
        name: "Quebec City",
        age: "F1 / F2 / B1 / B2 / H1B / H4 / J1 / J2 / L1 / L2 / O1 /O2 / O3",
        address: "2, rue de la Terrasse Dufferin uébec, QC, G1R 4N5 Canada",
        time:  getEtime('quebec'),
        etime: '2 months',
        utime: utime
    },
    {
        key: 6,
        name: "Toronto",
        age: "F1 / F2 / B1 / B2 / H1B / H4 / J1 / J2 / L1 / L2 / O1 /O2 / O3",
        address: "225 Simcoe Street Toronto, ON, Ontario, M5G 1S4 Canada",
        time: getEtime('toronto'),
        etime: '15 hrs',
        utime: utime
    },
    {
        key: 7,
        name: "Vancouver",
        age: "F1 / F2 / B1 / B2 / H1B / H4 / J1 / J2 / L1 / L2 / O1 /O2 / O3",
        address: "1075 West Pender Street Vancouver, BC, V6E 2M6 Canada",
        time: getEtime('vancouver'),
        etime: '6 hrs',
        utime: utime
    },
    ];

    const consulateData = [
        {
            name: '温哥华美国领事馆',
            location: "1075 W Pender St, Vancouver, BC V6E 2M6",
            emergency_contact: "604-685-4311",
            mail_location: "112 - 1231 Pacific Blvd Vancouver, BC V6Z 0E2",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '多伦多美国领事馆',
            location: "360 University Ave, Toronto, ON M5G 1S4",
            emergency_contact: "416-201-4056",
            mail_location: "360 University Ave, Toronto, ON M5G 1S4",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '卡尔加里美国领事馆',
            location: "615 MacLeod Trail SE Rocky Mountain Plaza Building Calgary, Alberta T2G 4T84",
            emergency_contact: "403-266-8962",
            mail_location: "615 MacLeod Trail SE Rocky Mountain Plaza Building Calgary, Alberta T2G 4T84",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '渥太华美国领事馆',
            location: "490 Sussex Dr, Ottawa, ON K1N 1G8",
            emergency_contact: "613-688-5335",
            mail_location: "490 Sussex Dr, Ottawa, ON K1N 1G8",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "受理",
            status: "正常",
            status_prop: "success"
        },
        {
            name: '魁北克美国领事馆',
            location: "2, rue de la Terrasse-Dufferin Québec, Québec G1R 4T9",
            emergency_contact: "418-692-2095",
            mail_location: "Succursale Haute-Ville, C.P. 939 Québec, Québec G1R 4T9",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "不受理",
            status: "停滞",
            status_prop: "warning"
        },
        {
            name: '哈利法克思美国领事馆',
            location: "1973 Upper Water Street Halifax, NS B3J 0A9",
            emergency_contact: "4902-429-2480",
            mail_location: "1973 Upper Water Street Halifax, NS B3J 0A9",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "不受理",
            status: "停滞",
            status_prop: "warning"
        },
        {
            name: '蒙特利尔美国领事馆',
            location: "1134 Sainte-Catherine Street West Montréal, Québec H3B 1H4",
            emergency_contact: "844-277-8805",
            mail_location: "955, 1re Avenue #USA Sainte-Catherine, Québec J5C 1C5",
            holiday: "https://ca.usembassy.gov/holiday-calendar/",
            expedict: "不受理",
            status: "停滞",
            status_prop: "warning"
        },
    ]

    const showModal = (index) => {
        SetConsularModalData(consulateData[index])
        SetConsularVisible(true);
    };
    
    const handleOk = () => {
        SetConsularVisible(false);
    };
    const handleCancel = () => {
        SetConsularVisible(false);
    }

    return (
    <React.Fragment>
        <Helmet>
            <title>CAEX Canada Express 开始咨询 - 做你专属的签证助手</title>
            <meta
                name="description"
                content="CAEX Canada Express - 总部位于加拿大温哥华，分部位于美国纽约，致力做你的专属的签证助手, 提供一站式美签和加签服务，从申请到预约再到到面试以至于贴签全程为你保驾护航。主要包括面签加急预约，面签加急申请，免面签申请，面签培训，面签材料准备"
            />
            <meta
                name="keywords"
                content="美签加急预约,美签办理,美签预约,美签申请,美签刷位置,美签刷签"
            />
        </Helmet>
        <div className="homePage_wrapper">
            <Modal title={consularModalData["name"]} width={780} centered={true} visible={consularVisible} onCancel={handleCancel} footer={null} onOk={handleOk}>
            <Descriptions title="" layout="vertical" bordered>
                <Descriptions.Item label="地点">{consularModalData["location"]}</Descriptions.Item>
                <Descriptions.Item label="紧急电话">{consularModalData['emergency_contact']}</Descriptions.Item>
                <Descriptions.Item label="邮寄地址">{consularModalData['mail_location']}</Descriptions.Item>
                <Descriptions.Item label="假期时间"><a href='https://ca.usembassy.gov/holiday-calendar/'>https://ca.usembassy.gov/holiday-calendar/</a></Descriptions.Item>
                <Descriptions.Item label="加急申请">
                {consularModalData['expedict']}
                </Descriptions.Item>
                <Descriptions.Item label="当前状态">
                <Badge status={consularModalData['status_prop']} text={consularModalData['status']}  />
                </Descriptions.Item>
            </Descriptions>
            </Modal>
            <Header index={true}/>
                <section className='sec-1'>
                    <img alt="front-page" src={vector} className='front-page-img-vector'></img>
                    <img alt="front-page" src={vector2} className='front-page-img-vector2'></img>
                    <div className='welcome-container'>
                        <h1>
                            {Text.index0[locale]}
                            {/* <span>YOUR RELIABLE</span>FULL-SERVICE VISA PROFESSIONAL AGENCY */}
                        </h1>
                        <h1> {Text.index01[locale]} <span>CAEX</span>{Text.index02[locale]}</h1>
                        { mobile === true && <h5> {Text.index03m[locale]}</h5> }
                        { mobile === false && <h5> {Text.index03[locale]}</h5> }

                        {/* { mobile === false && <img alt="front-page" src={vector} className='front-page-img-vector' />} */}
                        {/* { mobile === true && <img alt="front-page" src={frontpage_mb} className='front-page-img-mb' />} */}
                     
                        {/* <div className='sentence-container'>
                            <span className="welcome-s1">
                                “美签面试有着落了吗?”
                            </span>
                            <span className="welcome-s2">
                                “问<span className='welcome-brand'>Canada Express</span>吧！” 
                            </span>
                        </div>
                        <img src={welcomeWomen} alt="welcome-women" className="welcome-women"></img>
                        <img src={welcomeMan}  alt="welcome-man" className="welcome-man"></img> */}
                    </div>
                </section>
                <section className='sec-5'>
                    {/* <div className='success-header'>
                        <ClusterOutlined  className="img" />
                        <div className='header-l'>
                            <p className='p-title'>成功案例 · 新榜</p>
                            <p className='p-title-desc'>3天内最新成功预约情况</p>
                        </div>
                        <div className='header-r '>
                            <p className='p-title'>小红书 · 攻略 </p>
                            <p className='p-title-desc'>最新美签相关实用帖</p>
                        </div>
                    </div> */}
                    {/* <div className='quanfangwei'>
                        <img src={quanfangwei} alt="" />
                    </div> */}
                    <div className='success-container'>
                        <div className='module-l'>
                            <div className='module-header'>
                                <AlertOutlined className="img"/>
                                <div>
                                    <p className='p-title'>{Text.index10[locale]}</p>
                                    <p className='p-title-desc'>{Text.index11[locale]}</p>
                                </div>
                            </div>
                            <div className='module-list'>
                                <div className='list-product'>
                                    <div className='content'>
                                        <div className='pic'>
                                            {/* <div className='visa_type'>{Text.index12[locale]}</div> */}
                                            {/* <div>{Text.index13[locale]}</div> */}
                                            <img src={f1} alt="f1" />
                                        </div>  
                                        <div className='detail'>
                                            <div className='detail_title'>{Text.index14[locale]}</div>
                                            <div className='detail_summary'>{Text.index15[locale]}</div>
                                            <div className='detail_username'>{Text.index16[locale]}</div>
                                            <div className='time_wrap'>
                                                <div className="reserve_time">{Text.index17[locale]}</div>
                                                <div className='plan_name'>{Text.index18[locale]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='list-product'>
                                    <div className='content'>
                                        <div className='pic'>
                                            {/* <div className='visa_type'>{Text.index19[locale]}</div>
                                            <div>{Text.index20[locale]}</div> */}
                                            <img src={h1b} alt="h1b" />
                                        </div>
                                        <div className='detail'>
                                            <div className='detail_title'> {Text.index21[locale]}</div>
                                            <div className='detail_summary'>{Text.index22[locale]}</div>
                                            <div className='detail_username'>{Text.index23[locale]}</div>
                                            <div className='time_wrap'>
                                                <div className="reserve_time">{Text.index24[locale]}</div>
                                                <div className='plan_name'>{Text.index25[locale]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='list-product'>
                                    <div className='content'>
                                        <div className='pic'>
                                            {/* <div className='visa_type'>{Text.index26[locale]}</div>
                                            <div>{Text.index27[locale]}</div> */}
                                             <img src={b1b2} alt="b1b2" />
                                        </div>
                                        <div className='detail'>
                                            <div className='detail_title'>{Text.index28[locale]}</div>
                                            <div className='detail_summary'>{Text.index29[locale]}</div>
                                            <div className='detail_username'>{Text.index30[locale]}</div>
                                            <div className='time_wrap'>
                                                <div className="reserve_time">{Text.index31[locale]}</div>
                                                <div className='plan_name'>{Text.index32[locale]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='list-product'>
                                    <div className='content'>
                                        <div className='pic'>
                                            {/* <div className='visa_type'>{Text.index33[locale]}</div>
                                            <div>{Text.index34[locale]}</div> */}
                                             <img src={b1b2} alt="b1b2" />
                                        </div>
                                        <div className='detail'>
                                            <div className='detail_title'> {Text.index35[locale]}</div>
                                            <div className='detail_summary'>{Text.index36[locale]}</div>
                                            <div className='detail_username'>{Text.index37[locale]}</div>
                                            <div className='time_wrap'>
                                                <div className="reserve_time">{Text.index38[locale]}</div>
                                                <div className='plan_name'>{Text.index39[locale]}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='module-r'>
                            <div className='module-header-2'>
                                { mobile === true && <TagsOutlined className="img"/> }
                                <div>
                                    <p className='p-title'>{Text.index40[locale]}</p>
                                    <p className='p-title-desc'>{Text.index41[locale]}</p>
                                </div>
                            </div>     
                            <div className='module-list-2'>
                                <div className='list-product-2' onClick={e => { window.open("https://www.xiaohongshu.com/discovery/item/67b4d90a000000001203c6f4?source=webshare&xhsshare=pc_web&xsec_token=AB1TZ6a1w2ygYhj7ysd1cVARAWmpjw8fFgbJG6Adv2zuw=&xsec_source=pc_share")}}>
                                    <div className={mobile === false ? 'content-box cb-1' : 'content-box cb-1-mb'}>
                                        <div className='main-title'>
                                        {Text.index42[locale]}
                                        </div>
                                        <div className='sub-title'>
                                        {Text.index43[locale]}
                                        </div>
                                    </div>
                                </div>
                                <div className='list-product-2' onClick={e => {window.open("https://www.xiaohongshu.com/discovery/item/648771a40000000013030f46?source=webshare&xhsshare=pc_web&xsec_token=ABKA5_RFRtJsmzMDo7oM6hCFuAWlXyobn7IeHkGHMN3ok=&xsec_source=pc_share")}}>
                                    <div className={mobile === false ? 'content-box cb-2' : 'content-box cb-2-mb'}>
                                        <div className='main-title'>
                                        {Text.index44[locale]}
                                        </div>
                                        <div className='sub-title'>
                                        {Text.index45[locale]}
                                        </div>
                                    </div>
                                </div>
                                <div className='list-product-2' onClick={e => {window.open("https://www.xiaohongshu.com/discovery/item/647fb5fc000000001203e4b3?source=webshare&xhsshare=pc_web&xsec_token=AB8_kzc38F-WvM-ixskj0-f9QOWSM4VCAxf-jQRIqcqrQ=&xsec_source=pc_share")}}>
                                    <div className={mobile === false ? 'content-box cb-3' : 'content-box cb-3-mb'}>
                                        <div className='main-title'>
                                        {Text.index46[locale]}
                                        </div>
                                        <div className='sub-title'>
                                        {Text.index47[locale]}
                                        </div>
                                    </div>
                                </div>
                            </div>                      
                        </div>
                    
                    </div>

                </section>
                <section className='sec-2'>
                    <div className='header-consular'>
                        <EnvironmentOutlined className="img"/>
                        {/* <img className="img" src={tableLogo} alt='table-logo'>
                        </img> */}
                        <div>
                            <p className='p-title'> {Text.index48[locale]}</p>
                            <p className='p-title-desc'> {Text.index49[locale]}</p>
                        </div>
                    </div>
                    <div className='home-consular'>
                        <div className="courseCard" onClick={e => showModal(0)} >
                            <div className="imgCourseCon"> 
                                <img src={vancover} alt="" className="imgCourse"/>
                                <p className="courseTitle">温哥华</p>
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">British Columbia</p> */}
                                {/* <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag">{Text.index50[locale]}</p>
                                    <p className="courseTag">{Text.index51[locale]}</p>
                                    <p className="courseTag">{Text.index52[locale]}</p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className="introPrice">954人已预约</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseCard" onClick={e => showModal(1)} >
                            <div className="imgCourseCon"> 
                                <img src={toronto} alt="" className="imgCourse"/>
                                <p className="courseTitle">多伦多</p>
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">Ontario | 1-4周</p>
                                <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag">{Text.index53[locale]}</p>
                                    <p className="courseTag">{Text.index54[locale]}</p>
                                    <p className="courseTag">{Text.index55[locale]}</p>
                                    <p className="courseTag">{Text.index56[locale]}</p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className={styles.introPrice}><FormattedMessage id="global.priceSymbol" />{coursePrice}</p>
                                    <Link className={styles.gotoCourse} to="/course/ibd/1" data-seed={Tools.isShit(location) ? `course-${courseObj.course_code}-${courseObj.level}-goto-course01-01` : `${location}-goto-course01-01`}>查看课程<img src={img_arrow} alt="" className={styles.imgArrow}/></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseCard" onClick={e => showModal(2)} >
                            <div className="imgCourseCon"> 
                                <img src={calgary} alt="" className="imgCourse"/>
                                <p className="courseTitle">卡尔加里</p>
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">Alberta | 1-3周</p>
                                <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag">{Text.index57[locale]}</p>
                                    <p className="courseTag">{Text.index58[locale]}</p>
                                    <p className="courseTag">{Text.index59[locale]}</p>
                                    <p className="courseTag">{Text.index60[locale]}</p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className={styles.introPrice}><FormattedMessage id="global.priceSymbol" />{coursePrice}</p>
                                    <Link className={styles.gotoCourse} to="/course/ibd/1" data-seed={Tools.isShit(location) ? `course-${courseObj.course_code}-${courseObj.level}-goto-course01-01` : `${location}-goto-course01-01`}>查看课程<img src={img_arrow} alt="" className={styles.imgArrow}/></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseCard"onClick={e => showModal(3)}>
                            <div className="imgCourseCon"> 
                                <img src={ottawa} alt="" className="imgCourse"/>
                                <p className="courseTitle">渥太华</p>
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">Ontario | 2-3周</p>
                                <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag">{Text.index61[locale]}</p>
                                    <p className="courseTag">{Text.index62[locale]}</p>
                                    <p className="courseTag">{Text.index63[locale]}</p>
                                    <p className="courseTag">{Text.index64[locale]}</p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className={styles.introPrice}><FormattedMessage id="global.priceSymbol" />{coursePrice}</p>
                                    <Link className={styles.gotoCourse} to="/course/ibd/1" data-seed={Tools.isShit(location) ? `course-${courseObj.course_code}-${courseObj.level}-goto-course01-01` : `${location}-goto-course01-01`}>查看课程<img src={img_arrow} alt="" className={styles.imgArrow}/></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseCard" onClick={e => showModal(5)}>
                            <div className="imgCourseCon"> 
                                <img src={halifax} alt="" className="imgCourse"/>
                                <p className="courseTitle">哈利法克思</p>
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">Nova Scotia | 3-4周</p>
                                <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag">{Text.index68[locale]}</p>
                                    <p className="courseTag">{Text.index69[locale]}</p>
                                    <p className="courseTag">{Text.index70[locale]}</p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className={styles.introPrice}><FormattedMessage id="global.priceSymbol" />{coursePrice}</p>
                                    <Link className={styles.gotoCourse} to="/course/ibd/1" data-seed={Tools.isShit(location) ? `course-${courseObj.course_code}-${courseObj.level}-goto-course01-01` : `${location}-goto-course01-01`}>查看课程<img src={img_arrow} alt="" className={styles.imgArrow}/></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseCard" onClick={e => showModal(4)}>
                            <div className="imgCourseCon"> 
                                <img src={quebec} alt="" className="imgCourse"/>
                                <p className="courseTitle">魁北克</p>
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">Quebec | 1-3周</p>
                                <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag">{Text.index65[locale]}</p>
                                    <p className="courseTag">{Text.index66[locale]}</p>
                                    <p className="courseTag">{Text.index67[locale]}</p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className={styles.introPrice}><FormattedMessage id="global.priceSymbol" />{coursePrice}</p>
                                    <Link className={styles.gotoCourse} to="/course/ibd/1" data-seed={Tools.isShit(location) ? `course-${courseObj.course_code}-${courseObj.level}-goto-course01-01` : `${location}-goto-course01-01`}>查看课程<img src={img_arrow} alt="" className={styles.imgArrow}/></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseCard" onClick={e => showModal(6)} >
                            <div className="imgCourseCon"> 
                                <img src={montreal} alt="" className="imgCourse"/>
                                <p className="courseTitle">蒙特利尔</p>
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">Quebec | 6周以上</p>
                                <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag">{Text.index71[locale]}</p>
                                    <p className="courseTag">{Text.index72[locale]}</p>
                                    <p className="courseTag">{Text.index73[locale]}</p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className={styles.introPrice}><FormattedMessage id="global.priceSymbol" />{coursePrice}</p>
                                    <Link className={styles.gotoCourse} to="/course/ibd/1" data-seed={Tools.isShit(location) ? `course-${courseObj.course_code}-${courseObj.level}-goto-course01-01` : `${location}-goto-course01-01`}>查看课程<img src={img_arrow} alt="" className={styles.imgArrow}/></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="courseCard ignore" >
                            <div className="imgCourseCon"> 
                                <img src={quebec} alt="" className="imgCourse"/>
                                {/* <p className="courseTitle">魁北克</p> */}
                                {/* <p className="courseTitle2">美领事馆</p> */}
                            </div>
                            <div className="coreCardDesContainer">
                                {/* <FireOutlined className="iconCollection" />
                                <p className="courseDes">Quebec | 4-6周</p>
                                <hr className="cardDvider"/> */}
                                <div className="courseTags">
                                    <p className="courseTag"></p>
                                    <p className="courseTag"></p>
                                    <p className="courseTag"></p>
                                </div>
                                {/* <div className="cardBottom">
                                    <p className={styles.introPrice}><FormattedMessage id="global.priceSymbol" />{coursePrice}</p>
                                    <Link className={styles.gotoCourse} to="/course/ibd/1" data-seed={Tools.isShit(location) ? `course-${courseObj.course_code}-${courseObj.level}-goto-course01-01` : `${location}-goto-course01-01`}>查看课程<img src={img_arrow} alt="" className={styles.imgArrow}/></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className='sec-4'>
                    <div className='header-flow'>
                        <ClusterOutlined  className="img" />
                        <div>
                            <p className='p-title'>{Text.index74[locale]}</p>
                            <p className='p-title-desc'>{Text.index75[locale]}</p>
                        </div>
                    </div>
                    <div className='flow-container'>
                        <img className='flow_bg' src={flow_bg} alt="flow-bg"></img>
                        <div className='flow-card'>
                            <div className='flow-left'>
                                <div className='step'>
                                    {/* <img src={step1} alt="step1" /> */}
                                    <span><UsergroupAddOutlined  className="step-icon"/>{Text.index76[locale]}</span>
                                </div>
                                <div className='arrow'>
                                    <img src={arrow} alt="arrow" />
                                </div>

                            </div>
                            <div className='flow-right'>
                                <div>
                                {Text.index77[locale]}
                                <br/>
                                {Text.index78[locale]}
                                </div>
                            </div>
                        </div>
                        <div className='flow-card'>
                            <div className='flow-left'>
                                <div className='step'>
                                    {/* <img src={step1} alt="step1" /> */}
                                    <span><CheckCircleOutlined className="step-icon"/>{Text.index79[locale]}</span>
                                </div>
                                <div className='arrow'>
                                    <img src={arrow} alt="arrow" />
                                </div>

                            </div>
                            <div className='flow-right'>
                                <div>
                                {Text.index80[locale]}
                                <br/>
                                {Text.index81[locale]}
                                </div>
                            </div>
                        </div>
                        <div className='flow-card'>
                            <div className='flow-left'>
                                <div className='step'>
                                    {/* <img src={step1} alt="step1" /> */}
                                    <span> <MoneyCollectOutlined className="step-icon"/>{Text.index82[locale]}</span>
                                </div>
                                <div className='arrow'>
                                    <img src={arrow} alt="arrow" />
                                </div>

                            </div>
                            <div className='flow-right'>
                                <div>
                                {Text.index83[locale]}
                                </div>
                            </div>
                        </div>
                        <div className='flow-card'>
                            <div className='flow-left'>
                                <div className='step'>
                                    {/* <img src={step1} alt="step1" /> */}
                                    <span><RocketOutlined className="step-icon"/>{Text.index84[locale]}</span>
                                </div>
                                <div className='arrow arrow-hide'>
                                    <img src={arrow} alt="arrow" />
                                </div>

                            </div>
                            <div className='flow-right'>
                                <div>
                                {Text.index85[locale]}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='sec-3'>
                    <div className='header-table'>
                        <ScheduleFilled className="img" />
                        {/* <img className="img" src={tableLogo} alt='table-logo'>
                        </img> */}
                        <div>
                            <p className='p-title'>{Text.index86[locale]}</p>
                            <p className='p-title-desc'>{Text.index88[locale]}</p>
                            {/* <br/> <span style={{fontSize: 12}}>{Text.index88[locale]}</span></p> */}
                        </div>
                    </div>
                    <div className='home-table'>
                        <Table columns={columns} dataSource={data} pagination={false}/>
                    </div>
                    <div className='home-table-mb'>
                    {/* <Result
                            // icon={<SmileOutlined />}
                            // status="warning"
                            title="数据表格仅支持电脑端浏览，请使用电脑后再次访问"
                            // extra={<Button type="primary">Next</Button>}
                        /> */}
                         <Table columns={columns_mobile} dataSource={data} pagination={false}/>
                    </div>
                </section>
            <Footer/>
        </div>
    </React.Fragment>
   )
}