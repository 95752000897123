import React, {useState, useEffect} from 'react'
import Header from '../../components/header'
import { Helmet } from 'react-helmet';
import { useLocaleContext } from '../../utils/context'
import { submitDS160Form } from  '../../api/user' 
import { Modal, Tag, Form, Input, Button, message, Select, DatePicker, Segmented, Result } from 'antd';
import { CheckCircleOutlined, FilePdfOutlined, UserOutlined, WechatOutlined, MailOutlined} from '@ant-design/icons';
import img_line from '../../asset/img/docs_line.png'
import './index.less'
import { Text } from '../../utils/locale'
import wechat_pay_code from '../../asset/img/wechat_pay_code.png'
import alipay_pay_code from '../../asset/img/alipay_pay_code.png'

export default function Index() {

  const [ tido, Settido] = useState(true)
  const [ page, SetPage] = useState(1)
  const [ segment, SetSegment] = useState('info')
  const [ payment, SetPayment] = useState('wechat')
  const [ status, SetStatus] = useState(null)
  const [ success, SetSuccess] = useState(false)
  const { locale } = useLocaleContext()

  const hiddeTido = () => {
    var element = document.querySelector('#tidio-chat');
    if (element !== null) {
        element.style.display = 'none'
    } else {
        Settido(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
        hiddeTido()
    }, 2000)
  },[])

  const handlePrev = () => {
    if (page === 3 ) {
        SetSegment('pay')
    }
    if (page === 2) {
        SetSegment('info')
    }
    SetPage(page-1)
    return
  }
  const  changePayment = (value) => {
    SetPayment(value)
  }

  const changeStatus = (value) => {
    SetStatus(value)
  }


  const handleNext = () => {
      if (page !== 3) {
        form.validateFields().then(values => {
            if (page === 1 ) {
                SetSegment('pay')
    
            }
            if (page === 2) {
                SetSegment('confirm')
            }
            console.log(values)
            SetPage(page+1)            
        }).catch(errorInfo => {
            console.log(errorInfo)
        })
        return
      }
      else {
        form.validateFields().then(values => { 
            SetSuccess(true)
        }).catch(errorInfo => {
            console.log(errorInfo)
        })
        // form.validateFields().then(values => {
        //     // form.resetFields()
        //     SetSuccess(true)
        //     submitDS160Form({
        //         ...values
        //         // passport: values.passport, 
        //         // passport_city: values.passport_city, 
        //         // passport_issue: values.passport_issue, 
        //         // passport_expire: values.passport_expire,
        //         // fullname: values.fullname,
        //         // nativename: values.nativename,
        //         // formername: values.formername,
        //         // passportID: values.passportID,
        //         // gender: values.gender,
        //         // birthdate: values.birthdate,
        //         // birthcountry: values.birthcountry,
        //         // birthstate: values.birthstate,
        //         // birthcity: values.birthcity,
        //         // idnumber: values.idnumber,
        //         // ssn: values.ssn,
        //         // tcn: values.tcn,
        //         // address: values.address,
        //         // pr: values.pr,
        //         // worktel: values.worktel,
        //         // emaillist: values.emaillist,
        //         // tel: values.tel,
        //         // forigenlan: values.forigenlan,
        //         // arrested: values.arrested,
        //         // fathername: values.fathername,
        //         // fatherbirth: values.fatherbirth,
        //         // fatherinUS: values.fatherinUS,
        //         // mothername: values.mothername,
        //         // mothebirth: values.mothebirth,
        //         // motherinUS: values.motherinUS,
        //         // marrystatus: values.marrystatus,
        //         // spousename: values.spousename,
        //         // spousebirth: values.spousebirth,
        //         // spousenational: values.spousenational,
        //         // marrydate: values.marrydate,
        //         // divorcedate: values.divorcedate,
        //         // currworkplacename: values.currworkplacename,
        //         // currworkplacenameEN: values.currworkplacenameEN,
        //         // currworkplaceaddress: values.currworkplaceaddress,
        //         // currworkplacetel: values.currworkplacetel,
        //         // currworkplaceadvisorname: values.currworkplaceadvisorname,
        //         // currworkplaceadvisortel: values.currworkplaceadvisortel,
        //         // currworkposition: values.currworkposition,
        //         // currworkpstartdate: values.currworkpstartdate,
        //         // montlyincome: values.montlyincome,
        //         // socialmedia: values.socialmedia,
        //         // trippurpose: values.trippurpose,
        //         // tripcity: values.tripcity,
        //         // tripfirsnightaddress: values.tripfirsnightaddress,
        //         // tripfirstnighttel: values.tripfirstnighttel,
        //         // tripfellow: values.tripfellow,
        //         // tripfellowrelation: values.tripfellowrelation,
        //     }).then(res => {
        //     if (res.status === 200) {
        //         message.success(res.message)
        //         form.resetFields()
        //     } else {
        //         message.warning(res.message)
        //     }
        //     })
        // }).catch(err => {
        //     message.warning('信息未填完整，请仔细检查！',6)
        //     return
        // })
      }
  }
  const { Option } = Select;
  const [form] = Form.useForm()
  return (
      <React.Fragment>
          <Helmet>
              <title>预约信息填写</title>
              <meta
              name="description"
              content="美签预约提交"
              />
              <meta
              name="keywords"
              content="美签咨询，美签预约，美签免面签申请，美签申请，美签代办，美签加急"
              />
          </Helmet>
          <Header blank={true}/>
          <div className="appoinment_container">
              <div className='ds160_form'>
                  <h2 className="con_title">美签预约 / 加急提交</h2>
                  {/* <img className="img_line" src={img_line} alt=""></img> */}
                  <div className='con_title_line'></div>
                  {/* <p className="con_tip">{Text.dsform106[locale]}</p>
                  <p className="con_tip">{Text.dsform107[locale]}</p>
                  <p className="con_tip">{Text.dsform108[locale]}</p> */}
                  { success === false &&
                  <Segmented
                    className='segment'
                    defaultValue={'info'}
                    value = {segment}
                    block
                    options={[
                        { label: '预约信息', value: 'info', disabled: page === 1 ? false : true },
                        { label: '确认安排', value: 'pay', disabled: page === 2 ? false : true },
                        { label: '完成', value: 'confirm',disabled: page === 3 || 4 ? false : true },
                    ]}
                    />}
                  <div className="container">
                          <>
                          {/* <Icon type="caret-right" />  */}
                          {/* <div className="methodDes">请任选一种方式联系我们，发送咨询</div> */}
                          <div className="wrap_consult">
                              <div className="left">
                                { success === false &&
                                  <Form 
                                      layout="vertical"
                                      form={form}
                                      className="form_content"
                                      size="middle"
                                  >
                                    { page === 1 &&
                                    <>  
                                        <Form.Item
                                          label="签证类型"
                                        //   label={Text.dsform20[locale]}
                                          name="type"
                                          type="password"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          {/* <Input placeholder={Text.dsform0[locale]}/> */}
                                          <Select
                                                // onChange={handleChange}
                                                placeholder="选择签证类型"
                                                options={[
                                                    {
                                                        label: 'B1 / B2 Buiness & Tourism (Temporary vistor)',
                                                        value: 'B1/B2',
                                                    },
                                                    {
                                                        label: 'F1 / F2 Student (And Spouse)',
                                                        value: 'F1',
                                                    },
                                                    {
                                                        label: 'H1B / H4 Speciality Occupation (And Spouse)',
                                                        value: 'H1B',
                                                    },
                                                    {
                                                        label: 'L1 / L2 Intra-company Transferee (And Spouse)',
                                                        value: 'L1',
                                                    },
                                                    {
                                                        label: 'O1 / O2 Extraordinary Ability (And Spouse)',
                                                        value: 'O1',
                                                    },
                                                    {
                                                        label: 'J1 / J2 Work Exchange Vistor (And Spouse)',
                                                        value: 'J2',
                                                    },
                                                    {
                                                        label: 'M1 / M2 Student (And Spouse)',
                                                        value: 'M1',
                                                    },
                                                    {
                                                        label: 'TN / TD NAFTA Professional (And Spouse)',
                                                        value: 'TN',
                                                    },
                                                ]}    
                                            />
                                        </Form.Item> 
                                        <Form.Item 
                                            label="面签城市" 
                                            name="location"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: Text.dsform0[locale],
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="选择签证城市"
                                                style={{ width: 150 }}
                                                options={[
                                                    {
                                                        label: 'Vancouver',
                                                        value: '温哥华',
                                                    },
                                                    {
                                                        label: 'Toronto',
                                                        value: '多伦多',
                                                    },
                                                    {
                                                        label: 'Calgary',
                                                        value: '卡尔加里',
                                                    },
                                                    {
                                                        label: 'Halifax',
                                                        value: '哈利法克思',
                                                    },
                                                    {
                                                        label: 'Ottawa',
                                                        value: '渥太华',
                                                    },
                                                    {
                                                        label: 'Montreal',
                                                        value: '蒙特利尔',
                                                    },
                                                    {
                                                        label: 'Quebec City',
                                                        value: '魁北克',
                                                    },
                                                ]}    
                                            />
                                        </Form.Item>
                                        <Form.Item
                                          label="美签账号邮箱"
                                        //   label={Text.dsform20[locale]}
                                          name="email"
                                          type="email"
                                          hasFeedback
                                          style={{ width: 250 }}
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                        >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                        </Form.Item> 
                                        <Form.Item
                                          label="美签账号密码"
                                        //   label={Text.dsform20[locale]}
                                          name="password"
                                          type="password"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                        >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                        </Form.Item>
                                        <Form.Item
                                          label="主要联系方式"
                                        //   label={Text.dsform20[locale]}
                                            name="notes"
                                            hasFeedback
                                            rules={[
                                            {
                                                required: true,
                                                message: Text.dsform0[locale],
                                            },
                                            ]}
                                        >
                                          <Input placeholder="如: 微信号 | Whatsapp | Phone: 12345"/>
                                        </Form.Item> 

                                        <Form.Item label="当前已预约日期">
                                            <DatePicker placeholder="选择日期 （非必填）" name="date_now" style={{ width: 280 }}/>
                                        </Form.Item>
                                        <Form.Item label="期望面试日期">
                                            <DatePicker placeholder="选择日期 （非必填）" name="date_to" style={{ width: 280 }}/>
                                        </Form.Item>
                                    </>
                                    }
                                    { page === 2 &&
                                        <>
                                        <Form.Item 
                                            label="费用价格 （币种）" 
                                            name="price"
                                        >
                                            <Tag  style={{ padding: 3, fontSize: 14, marginBottom:6}}>🇨🇦 CAD $ 114.45 ( Tax incl )</Tag>
                                            <Tag color="volcano" style={{padding: 3,fontSize: 14}}>🇨🇳 RMB ￥600.00 ( Tax incl )</Tag>
                                        </Form.Item>
                                        <Form.Item 
                                            label="付款方式" 
                                            name="pay_method"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: Text.dsform0[locale],
                                                },
                                            ]}
                                        >
                                            <Select
                                                onChange={changePayment}
                                                placeholder="请选择其中一种方式"
                                                style={{ width: 220 }}
                                                options={[
                                                    {
                                                        label: '微信 RMB (5% off)',
                                                        value: 'wechat',
                                                    },
                                                    {
                                                        label: '支付宝 RMB (5% off)',
                                                        value: 'alipay',
                                                    },
                                                    {
                                                        label: '银行卡 CAD / USD',
                                                        value: 'card',
                                                    },
                                                    {
                                                        label: '3rd Party (Zella / Venmo)',
                                                        value: 'usd',
                                                    },
                                                ]}    
                                            />
                                        </Form.Item> 
                                        </> 
                                    }
                                    { page === 3 &&
                                    <>
                                      {payment === 'wechat' &&
                                        <Form.Item 
                                            label="手机扫描下方二维码付款" 
                                            name="wechat"
                                        >
                                            <div className='qrcode_pay'>
                                                <img src={wechat_pay_code} alt="wechat"></img>
                                            </div>
                                        </Form.Item> } 
                                        {payment === 'alipay' &&
                                        <Form.Item 
                                            label="手机扫描下方二维码付款" 
                                            name="alipay"
                                        >
                                            <div className='qrcode_pay'>
                                                <img src={alipay_pay_code} alt="alipay"></img>
                                            </div>
                                        </Form.Item> }
                                        {payment === 'card' &&
                                        <Form.Item 
                                            label="点击下方按钮付款" 
                                            name="card"
                                        >
                                            <Button type="primary" size="medium" onClick={()=> window.open('https://offerbang.io')}>前往付款</Button>
                                        </Form.Item> }
                                        {payment === 'usd' &&
                                        <Form.Item 
                                            label="请联系专人处理" 
                                            name="contact_us"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: Text.dsform0[locale],
                                                },
                                            ]}
                                        >
                                            <Tag color="lime" style={{fontSize: 15}}><WechatOutlined style={{fontSize: 22}} />: caex-canadaexpress</Tag>
                                        </Form.Item> }
                                        <Form.Item 
                                            label="确认付款状态" 
                                            name="status"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: Text.dsform0[locale],
                                                },
                                            ]}
                                        >
                                            <Select
                                                onChange={changeStatus}
                                                placeholder="请选择你的付款状态"
                                                style={{ width: 230 }}
                                                options={[
                                                    {
                                                        label: '已成功付款',
                                                        value: 1,
                                                    },
                                                    {
                                                        label: '付款遇到问题 (有问题选我）',
                                                        value: 0,
                                                    },
                                                    {
                                                        label: '人工联系已解决确认',
                                                        value: 2,
                                                    }
                                                ]}    
                                            />
                                        </Form.Item> 
                                        {status === 0 &&
                                        <Form.Item 
                                            label="请联系专人处理" 
                                            name="alipay"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: Text.dsform0[locale],
                                                },
                                            ]}
                                        >
                                            <Tag color="lime" style={{fontSize: 15}}><WechatOutlined style={{fontSize: 22}} />: can-express</Tag>
                                        </Form.Item> }
                                        {status === 2 &&
                                        <Form.Item 
                                            label="输入确认码" 
                                            name="confirm_code"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: Text.dsform0[locale],
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入核实确认码"/>
                                        </Form.Item> }
                                    </>
                                    }   
                                      {/* <Form.Item
                                          label={Text.dsform6[locale]}
                                          name="passport"
                                          hasFeedback
                                          rules={[
                                              {
                                                  required: true,
                                                  message: Text.dsform0[locale],
                                              },
                                          ]}
                                      >
                                          <Input placeholder={Text.dsform0[locale]}/>
                                      </Form.Item>                       */}
                                      {/* <Form.Item
                                          name="type" 
                                          hasFeedback                                      
                                      >
                                          <Select placeholder="选择类型" size="large">
                                              <Option value="1">私人订制咨询30分钟</Option>
                                              <Option value="2">私人订制咨询无限时</Option>
                                              <Option value="3">美签申请</Option>
                                              <Option value="4">其他</Option>
                                          </Select>
                                      </Form.Item> */}
                                      <Form.Item>
                                            <div className='foot_btn_container'> 
                                                <Button type="info" size="medium" onClick={handlePrev}>
                                                {(page === 2 || page === 3) && <span>上一步</span>}
                                                </Button>
                                                {/* <Button type="info" size="large" onClick={handleNext}>
                                                    {(page === 1 || page === 2) && <span>下一步</span>}
                                                </Button> */}
                                                <Button type="primary" size="medium" onClick={handleNext}>
                                                    {(status === 1 || status === 2) && page === 3 && <span>提交</span>}
                                                    {(page === 1 || page === 2) && <span>下一步</span>}
                                                </Button>
                                           </div>
                                      </Form.Item> 
                                  </Form> }
                                  { success === true &&
                                    <Result
                                        status="success"
                                        title="任务提交成功"
                                        >
                                        <div className="new_res">
                                            <p>亲爱的小伙伴，</p>
                                            <p>系统已经收到到你的提交，会立即帮你安排。</p>
                                        </div>
                                    </Result> }
                              </div> 
                              {/* <div className="right">
                                  <div className="methodTitle"><strong>方式二</strong></div>
                                  <div className="con">
                                      <img src={rosalyn_wechat} alt="rosalyn_wechat" />
                                      <p>扫码免费预约时间
                                          <br />
                                          订制属于你的专属咨询方案
                                      </p>
                                  </div>
                              </div> */}
                          </div>
                          </> 
                      {/* { page === 2 &&
                      <div>
                              <Result status="success" title="提交成功，请添加Lexy老师微信咨询~" extra={
                                  <div className={styles.advisor_qrcode}>
                                      <img className={styles.img_wechat} src={img_wechat} alt="qrcode"></img>
                                      <p>有任何问题可以联系Lexy老师</p>
                                  </div>
                              }/>
                      </div> } */}
                  </div>
              </div>
          </div>
      </React.Fragment>
  )
}