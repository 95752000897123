import './App.less';
import {
  BrowserRouter as Router, 
  Route, 
  Switch,
  Link
} from 'react-router-dom';
import Index from './pages/index'
import LandingPage from './pages/landingpage/index.jsx'
import UserLogin from './pages/user/login'
import UserReg from './pages/user/reg'
import RegBind from './pages/user/regbind'
import UserFindPw from './pages/user/forgetpw'
import Oauthloading from './pages/oauth/loading.jsx'
import UserProfile from './pages/user/profile'
import NoMatch from './pages/404'
import Faq from './pages/faq'
import Plan from './pages/plan/index'
import PlanTest from './pages/plan/index_test'
import PaySuccess from './pages/payresult/success';
import PaySuccessSubscribe from './pages/payresult/success_subscribe';
import PayCancel from './pages/payresult/cancel';
import Docs from './pages/docs';
import PlanOptions from './pages/plan/options/index';
import AppointmentCheck from './pages/appointment_check';
import Appointment from './pages/appointment';
import Agreement from './pages/policy/agreement';
import Policy from './pages/policy/policy';
import Xhs from './pages/xhs';
import Subscribe from './pages/subscribe';
import SubscribePolicy from './pages/policy/subscription';
import Dashboard from './pages/dashboard/index';
import Ds160 from './pages/ds160/index';
import IndexCA from './pages/indexCA'
// import { Helmet } from 'react-helmet';
// import Footer from './components/footer'
// // WebWorker helper class
// import WebWorker from './utils/webWorker';
// // Your web worker
// import LoopWorker from './worker/loopWorker';

function App() {
  // const workerInstance = new WebWorker(new LoopWorker('123'));
  // workerInstance.addEventListener("message", e => console.log(e.data), false);
  // workerInstance.postMessage("bar");  
  // workerInstance.onmessage = function(evt) {
  //     console.log('主收'+evt.data)
  // }
  return (
    <Router>
    <Switch>
       <Route path='/' component={Index} exact/>
       <Route path='/canada' component={IndexCA} exact/>
       <Route path='/country/select' component={LandingPage} exact/>
       <Route path='/faq' component={Faq} exact/>
       <Route path='/plan' component={Plan} exact/>
       <Route path='/docs' component={Docs} exact/>
       <Route path='/appointment' component={Appointment} exact/>
       <Route path='/appointment/check' component={AppointmentCheck} exact/>
       {/* <Route path='/plan/test' component={PlanTest} exact/> */}
       <Route path='/plan/options' component={PlanOptions} exact/>
       <Route path='/payresult/success' component={PaySuccess} exact/>
       <Route path='/payresult/cancel' component={PayCancel} exact/>
       <Route path='/user/login' component={UserLogin} />
       <Route path='/user/reg' component={UserReg} exact/>
       <Route path='/user/reg/bindemail' component={RegBind} />
       <Route path='/user/forgetpassword' component={UserFindPw} />
       <Route path='/user/oauth/validating' component={Oauthloading} />
       <Route path='/user/profile' component={UserProfile} />
       <Route path='/agreement' component={Agreement} />
       <Route path='/policy' component={Policy} />
       <Route path='/subscription_policy' component={SubscribePolicy} />
       <Route path='/xhs' component={Xhs} />
       <Route path='/subscribe' component={Subscribe} />
       <Route path='/payresult/success_subscribe' component={PaySuccessSubscribe} exact/>
       <Route path='/dashboard' component={Dashboard} exact/>
       <Route path='/ds160' component={Ds160} exact/>
       {/* <Route to='/auth_loading' component={Auth_loading} exact/> */}
       <Route path="*" component={NoMatch} />
    </Switch>
    {/* <Footer></Footer> */}
    {/* <Redirect to="/dashboard" /> : <PublicHomePage /> */}
    </Router>
  );
}

export default App;
