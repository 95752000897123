import React, {useState} from 'react'
import {Button,Collapse,Modal,Radio, Space, InputNumber} from 'antd'
import './index.less'
import img_background from '../../asset/img/plan/bg.png'
// import img_background_mobile from '../../asset/img/resume/resume_mobile.svg'
import img_5Percent from '../../asset/img/plan/5percent.png'
import img_20percent from '../../asset/img/plan/20percent.png'
import img_75Percent from '../../asset/img/plan/75percent.png'
import img_question from '../../asset/img/plan/question_boy.png'
import img_hightlight from '../../asset/img/plan/hightlight.png'
import img_h01 from '../../asset/img/plan/h01.png'
import img_h02 from '../../asset/img/plan/h02.png'
import img_h03 from '../../asset/img/plan/h03.png'
import img_h04 from '../../asset/img/plan/h04.png'
import img_about from '../../asset/img/plan/about.png'
import img_line from '../../asset/img/resume/h04.jpg'
import wechat from '../../asset/img/wechat.png'
// import img_greyplus from '../../asset/img/offerplus/plus_grey.png'
import img_target from '../../asset/img/offerplus/st-target.svg'
import img_bag from '../../asset/img/offerplus/bag.svg'
import img_people3 from '../../asset/img/offerplus/people-3.svg'
import img_check from '../../asset/img/resume/check.png'
import img_enroll_step from '../../asset/img/intro_enroll_step.svg'
// import img_logo1 from '../../asset/img/resume/Icon1.png'
import img_logo3 from '../../asset/img/plan/info.png'
import img_logo5 from '../../asset/img/plan/question.png'
import Header from '../../components/header'
import Footer from '../../components/footer'
import PlanConsultQrcode from '../../components/plan/consult_qrcode'
import { loadStripe } from '@stripe/stripe-js';
import { ThunderboltOutlined }from '@ant-design/icons';

const stripePromise = loadStripe('pk_test_51N4wIoHrsABESZ3IkqZzIqBSAMxaETOHrHQWaPEHe2LQpIK47fdGuxI62N43KyAN75kMnTCsoMgxBYVVpL5wgZZL00l6VYcmkH');
export default function Index() {
    const { Panel } = Collapse;  
    const [refVisibleConsult, setRefVisibleConsult] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [planSelect, setPlanSelect] = useState(1);
    const [num, setNum] = useState(1);
  
    const buyData = [ 
        {
        "allow_enroll": false, 
        "enroll_status": 0, 
        "has_discount": true, 
        "id": 1, 
        "in_cart": false, 
        "instruction": "Please note that your purchase will be nonrefundable after the completion of your first resume session.", 
        "is_enrolled": 0, 
        "level": 1, 
        "origin_price": 99.0, 
        "price": 39.0, 
        "status": 1, 
        "type": 2
        }, 
        {
        "allow_enroll": true, 
        "enroll_status": 0, 
        "has_discount": true, 
        "id": 2, 
        "in_cart": false, 
        "instruction": "Please note that your purchase will be nonrefundable after the completion of your first resume session.", 
        "is_enrolled": 0, 
        "level": 2, 
        "origin_price": 598.0, 
        "price": 398.0, 
        "status": 1, 
        "type": 2
        }, 
        {
        "allow_enroll": true, 
        "enroll_status": 0, 
        "has_discount": true, 
        "id": 3, 
        "in_cart": false, 
        "instruction": "Please note that your purchase will be nonrefundable after the completion of your first resume session.", 
        "is_enrolled": 0, 
        "level": 3, 
        "origin_price": 998.0, 
        "price": 798.0, 
        "status": 1, 
        "type": 2
        }
    ]

    const openConsult = e => {
        setRefVisibleConsult(true)
    }

    const changeVisibleConsult = e => {
        setRefVisibleConsult(e)
    }

    const showModal = () => {
        console.log(isModalOpen)
        setIsModalOpen(true);
      };
    
    const handleOk = () => {
        handleClick();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onPlanChange = (e) => {
        console.log('radio checked', e.target.value);
        setPlanSelect(e.target.value);
      };

    const handleClick = async (event) => {
        let successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqyy"
        let price = 'price_1N4x7nHrsABESZ3Ike9cKKE5'
        if (planSelect === 1) {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqyy"
            price = 'price_1N4x7nHrsABESZ3Ike9cKKE5'
        }
        if (planSelect === 2) {
            successUrl = window.location.origin+"/payresult/success?tk=a4dnu3kxnamqqfw"
            price = 'price_1N4xBYHrsABESZ3IRztBQRIO'
        }

        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          lineItems: [{
            price: price, // Replace with the ID of your price
            quantity: num,
          }],
          mode: 'payment',
          successUrl: successUrl,
          cancelUrl:  window.location.origin+'/payresult/cancel',
        });
        setIsModalOpen(false);
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
    };

    const onNumChange = (value) => {
        setNum(value)
    }

    return (
    <React.Fragment>
        <Header/>
        <PlanConsultQrcode refVisible={refVisibleConsult} changeVisible={changeVisibleConsult}/>
        <Modal
			width={400}
			visible={isModalOpen}
			onCancel={handleCancel}
			onOk={handleOk}
            title={ <span><ThunderboltOutlined style={{fontSize: 18,marginRight:5}}/>请选择服务类型</span>}
            centered={true}
            okText="前往支付"
            cancelText="返回"
			>
            <div>
            <span>服务：</span>
                <Radio.Group onChange={onPlanChange} value={planSelect}>
                    <Space direction="vertical">    
                        <Radio value={1}>面签预约</Radio>
                        <Radio value={2}>面签全方位</Radio>
                    </Space>
                </Radio.Group>
            </div>
            <div className='num-count'>
                <span>数量：</span><InputNumber min={1} max={4} defaultValue={1} onChange={onNumChange} />
            </div>
        </Modal>
        <div className="pkg_resume">
            <div className="btn_floating"> 
                <h4>问题咨询</h4>
                <img
                    src={wechat}
                    className="img_wechat_floating"
                    alt=""></img>
            </div>
            {/* 固定TOP NAV */}
            {/* Header Section */}
            <div className="header_resume">
                <div className="header_content">
                    <div className="header_des">
                        <h5 className="des_first">一站式美签服务</h5>
                        <p>解放双手让签证变得简单，“嗖”地一下就能出发</p>
                        <Button
                            onClick={e => openConsult(e)}
                            // onClick={handleClick}
                            data-seed="pkg-resume-buy-top"
                            className="btn_consult"
                            >咨询详情</Button>
                    </div>
                    <img className="background_img" src={img_background} alt=""></img>
                    <img className="backgound_img_mobile" src={img_background} alt=""></img>
                </div>
            </div>

            <div className="pcontent">
                {/* 项目目标banner */}
                <div className="package_details">
                    <div className="details_content">
                        <div className="package_detail">
                            {/* 不需要 css用border-left就实现 <Icon type="line" className="icon_line}/> */}
                            <img src={img_target} alt=""></img>
                            <p className="detail_title">服务目标</p>
                            <p className="detail_des">通过材料准备、润色、语言精修、完整性审核，全方位提升签证通过率</p>
                        </div>
                        <div className="package_detail">
                            {/* <Icon type="line" className="icon_line}/> */}
                            <img src={img_bag} alt=""></img>
                            <p className="detail_title">涵盖地区</p>
                            <p className="detail_des">加拿大 | 中国大陆 | 香港 | 其他（请联系我们）</p>
                        </div>
                        <div className="package_detail">
                            {/* <Icon type="line" className="icon_line}/> */}
                            <img src={img_people3} alt=""></img>
                            <p className="detail_title">适合人群</p>
                            <p className="detail_des">对签证文件不了解；没有时间预约位置，希望全面提升面签通过率的小伙伴</p>
                        </div>

                    </div>
                </div>
                {/* 关于简历 */}
                <div className="diagnoseContent">
                    <div className="aboutResume">
                        <div className="advantage">
                            <img src={img_about} className="img_hightlight" alt=""/>
                            <div className="advantageDes">
                                <h5>关于美签</h5>
                                <p>一次精心的准备，多年无忧进出</p>
                            </div>
                        </div>
                        <div className="rusume_des">
                            <h2 className="first_title">你知道吗?</h2>
                            {/* <img className="title_line" src={img_grey_line} alt=""></img> */}
                            <img className="des_line" src={img_line} alt=""></img>
                            <div className="description">
                                <div className="fact">
                                    <img src={img_20percent} alt=""></img>
                                    <h2 className="number">20</h2>
                                    <h5 className="unit">%</h5>
                                    <p>20%的人清楚面签提前准备的重要性，以及如何面签</p>
                                </div>

                                <div className="fact">
                                    <img src={img_5Percent} alt=""></img>
                                    <h2 className="number">5</h2>
                                    <h5 className="unit">个</h5>
                                    <p>系统平均每天最少会发放5个新的预约位置</p>
                                </div>

                                <div className="fact">
                                    <img src={img_75Percent} alt=""></img>
                                    <h2 className="number_3">75</h2>
                                    <h5 className="unit_3">%</h5>
                                    <p>75%的申请人在与签证官会面前就被机器自动淘汰</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="desContent">
                        <img src={img_question} alt=""></img>
                        <p>没有心仪的预约位置?<br/>
                            面签首次被拒问题出在哪？<br/>
                            面试官一般会什么问题，我应该准备什么文件？<br/>
                            如何提高面签成功率，斩获美帝签证？</p>
                    </div>
                    <p className="desContentBottom">自己做调研，辨别信息可用性需要反复琢磨，不如交给经验 丰富的签证助手帮你解决，精准高效地指出问题，锁定心仪面试！</p>

                    <div className="highlight">
                        <div className="advantage">
                            <img src={img_hightlight} className="img_hightlight" alt=""/>
                            <div className="advantageDes">
                                <h5>服务亮点</h5>
                                <p>拥有10年以上经验的签证助手逐行逐句检查文件，迅速提升面签通过率</p>
                            </div>
                        </div>
                        <div className="itemContain">
                            <div className="itemRow">
                                <div className="item">
                                    <img src={img_h01} alt=""/>
                                    <div className="des">
                                        <h2>专业：</h2>
                                        <div className="con">阅人无数的前美签面签官，帮你针对心仪签证，发现准备问题，成功拿下面试。</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src={img_h02} alt=""/>
                                    <div className="des">
                                        <h2>个性化：</h2>
                                        <div className="con">顾问会根据你的个人经历和申请类型，补充润色文件填写的不足之处。</div>
                                    </div>
                                </div>
                            </div>
                            <div className="itemRow">
                                <div className="item">
                                    <img src={img_h03} alt={img_h03}/>
                                    <div className="des">
                                        <h2>逐行改写润色：</h2>
                                        <div className="con">Native Speaker逐行语言润色，保证申请文件语言丰富地道合理。</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <img src={img_h04} alt={img_h04}/>
                                    <div className="des">
                                        <h2>全方位:</h2>
                                        <div className="con">申请材料准备 + DS160填写 + 预约账号准备 + 面签位置锁定，我们一步到位帮你全都搞定！</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 项目详情到最后*/}
                { buyData.length > 0 &&
                <div className="details" >
                {/* <div className="details" ref={detailRef} > */}
                    <div className="advantage">
                        <img src={img_logo3} className="img_hightlight" alt=""/>
                        <div className="advantageDes">
                            <h5>服务详情</h5>
                            <p>先咨询后服务，科学安排面签计划</p>
                        </div>
                    </div>

                    <div className="content">
                        <div name="forEdge">
                            <table className="table_main">
                                <thead className="normal">
                                    <tr>
                                        <th></th>
                                        <th>
                                            <div className="headBlueLight">面签预约</div>
                                        </th>
                                        <th>
                                            <div className="headBlue">面签全方位</div>
                                        </th>
                                    </tr>
                                </thead>
                                <thead className="mobile">
                                    <tr>
                                        <th></th>
                                        <th><div className="headBlueLight">预约</div></th>
                                        <th><div className="headBlue">全方位</div></th>
                                    </tr>
                                </thead>
                                <tbody className="tbody">
                                    <tr className="empty">
                                        <td></td>
                                        <td className="grey"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">位置锁定（Slot）</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                                - 1-3个心仪城市挑选</div>
                                            {/*  <div className="con">Present your story in a more compelling way with Indust
    * ry professionals experienced in recruiting. Highlight your strength and make 
    * the keywords stand out</div> 
    */
                                            }
                                            {/* <div className="time">60min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                                - 心仪预约时间挑选</div>
                                            {/*  <div className="con">Add some cosmetics from our language experts and say th
    * ings the right way</div> 
    */
                                            }
                                            {/* <div className="time">30min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                                - 专人客服进度跟踪</div>
                                            {/*  <div className="con">Line-by-line editing to make your resume reach its grea
    * test potential and show the real impact</div> 
    */
                                            }
                                            {/* <div className="time">60min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_second">
                                                - 人工智能技术24小时监控</div>
                                            {/*  <div className="con">Line-by-line editing to make your resume reach its grea
    * test potential and show the real impact</div> 
    */
                                            }
                                            {/* <div className="time">60min session</div> */}
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div className="con">面签文件 Checklist</div>
                                            {/*  <div className="con">On personalized process navigation & Weetok Sharer reco
    * mmendation</div> 
    */
                                            }
                                        </td>
                                        {/* <td></td> */}
                                        <td className="grey">
                                            {/* <img src={img_check} alt='check' className="imgCheck"></img> */}
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">DS160文件填写（检查润色）</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">签证预约账号准备</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">面签问题培训（Frequent Ask）</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con">私人订制 Q&A</div>
                                        </td>
                                        <td className="grey"></td> 
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        {/* <td className="grey"></td>
                                        <td className="time">60分钟</td> */}
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_bracket">【官方保证】1次改签服务</div>
                                        </td>
                                        <td className="grey">
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="con_bracket">【官方保证】免面签材料递交，面签加急申请</div>
                                        </td>
                                        <td className="grey"></td>
                                        <td>
                                            <img src={img_check} alt='check' className="imgCheck"></img>
                                        </td>
                                    </tr>
                                    <tr className="empty">
                                        <td></td>
                                        <td className="grey"></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            name="forEdge">
                            <table className="table_price">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>价格</div>

                                            {/* {
                                                Tools.getRegion() === 'en-GB' && <div className="ukPayDes">
                                                        * 英镑价格根据参考汇率换算<br/>
                                                        * 付款时需以美元支付
                                                    </div>
                                            } */}

                                        </td>
                                        <td className="grey">
                                            <div className="regularPrice">
                                                <span id="global.price">
                                                    $120 美金
                                                </span>
                                                {/* <FormattedMessage
                                                    id="global.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        price: buyData[0].origin_price,
                                                        price_foreign: buyData[0].origin_price_GBP
                                                    }}/> */}
                                            </div>
                                            <div className="realPrice">
                                                <span id="pkgresume.price">
                                                    $80 美金
                                                </span>
                                                {/* <FormattedMessage
                                                    id="pkgresume.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        span: (...chunks) => (
                                                            <span key="1" className="priceDollar">{chunks}</span>
                                                        ),
                                                        price: buyData[0].price,
                                                        price_foreign: buyData[0].price_GBP
                                                    }}/> */}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="regularPrice">
                                            <span id="global.price">
                                                    $350 美金
                                                </span>
                                                {/* <FormattedMessage
                                                    id="global.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        price: buyData[1].origin_price,
                                                        price_foreign: buyData[1].origin_price_GBP
                                                    }}/> */}
                                            </div>
                                            <div className="realPrice">
                                                <span id="pkgresume.price">
                                                    $200 美金
                                                </span>
                                                {/* <FormattedMessage
                                                    id="pkgresume.price"
                                                    defaultMessage="${price}"
                                                    values={{
                                                        span: (...chunks) => (
                                                            <span key="1" className="priceDollar">{chunks}</span>
                                                        ),
                                                        price: buyData[1].price,
                                                        price_foreign: buyData[1].price_GBP
                                                    }}/> */}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        {/* <table className="table_buy">
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <Button
                                            data-seed="pkg-resume-buy-2"
                                            className={`="BuyButton} ${ "btn1"}`}
                                            disabled={btn1Disabled}
                                            onClick={() => openPay(`${buyData[0].id}`)">{btnText0}</Button>
                                    </td>
                                    <td>
                                        <Button
                                            data-seed="pkg-resume-buy-3"
                                            className={`="BuyButton} ${ "btn2"}`}
                                            disabled={btn2Disabled}
                                            onClick={() => openPay(`${buyData[1].id}`)">{btnText1}</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}

                        {/* {
                            Tools.getRegion() === 'en-GB' && <div className="ukPayDesMobile">
                                    * 英镑价格根据参考汇率换算<br/>
                                    * 付款时需以美元支付
                                </div>
                        } */}
                    </div>
                </div>
                }
                
                <Button
                        // onClick={e => openConsult(e)}
                        onClick={showModal}
                        type="primary"
                        data-seed="pkg-resume-consult"
                        className="consultBtn">选择付款

                </Button>
            
                <div className="flowPath">
                    <p className="enroll_title">咨询计划 锁定你的签证
                    </p>
                    <p className="enroll_des">专业团队针对性计划安排 快人一步拿到签证</p>
                    <div className="enroll_step_con">
                        <div className="enroll_step1">添加顾问微信<br/>开始签证计划</div>
                        <img src={img_enroll_step} alt="" className="img_enroll_step"/>
                        <div className="enroll_step2">免费咨询<br/>订制签证计划</div>
                        <img src={img_enroll_step} alt="" className="img_enroll_step"/>
                        <div className="enroll_step3">支付费用</div>
                        <img src={img_enroll_step} alt="" className="img_enroll_step"/>
                        <div className="enroll_step4">
                            <span>信心满满的面签</span>
                        </div>
                    </div>
                </div>
                {/* 简历指南 */}
            </div>
            <div className="faq">
                <div className="advantage">
                    <img src={img_logo5} className="img_hightlight" alt=""/>
                    <div className="advantageDes">
                        <h5>常见问题</h5>
                    </div>
                </div>
                <Collapse bordered={false} className="faq_collapse">
                    <Panel header={<div data-seed="pkg-resume-faq01"> 预约抢位前需要我需要准备什么？</div>}>
                        <p className="faq_answer">需要填写好DS160表格拿到AA确认号，准备好一个付款好的预约号。</p>
                    </Panel>
                    <Panel header={<div data-seed="pkg-resume-faq02"> 付款后，可以预约几次位置？</div>}>
                        <p className="faq_answer">次数不限，我们会根据你的实际安排，约到最新的心仪位置。如需约不同的账号或者换账号约，需另行付费。</p>
                    </Panel>
                    <Panel
                        header={<div data-seed="pkg-resume-faq03"> 如果约不到心仪的位置能退费吗？</div>}>
                        <p className="faq_answer">如果刷不到我们可以根据你的计划和需求帮你安排新的签证位置，如果你需要中断服务,需要你自己承担费用喔。</p>
                    </Panel>
                    <Panel
                        header={<div data-seed="pkg-resume-faq04"> 服务时长多久? </div>}>
                        <p className="faq_answer">一共为期1年的服务时间。</p>
                    </Panel>
                    <Panel
                        header={<div data-seed="pkg-resume-faq04"> 有活动或者优惠吗? </div>}>
                        <p className="faq_answer">目前价格已经是最新的优惠价格，随时会恢复原价。我们有推荐奖励，详情请咨询客服，</p>
                    </Panel>
                </Collapse>
            </div>
        </div>
    <Footer/>
    </React.Fragment>
  )
}